import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import React, { MouseEventHandler, useCallback, useMemo } from 'react'
import { BodyCell } from './styles'
import { useTableContext } from './TableContext'
import { TableColumn } from './types'
import { useObservedValue } from './useObservedValue'

export type TableBodyCellProps = {
  column: TableColumn<unknown>
  row: unknown
}

export const TableBodyCell = observer(function TableBodyCell({ column, row }: TableBodyCellProps) {
  const {
    props: { onRowClick },
  } = useTableContext()
  const { width, disableResizing, renderCell } = column

  const rowJs = useObservedValue(row)

  const cell = useMemo(() => renderCell(rowJs), [renderCell, rowJs])

  const handleCellClick = useCallback<MouseEventHandler<HTMLDivElement>>(
    (ev) => {
      // chect all parents of ev.target to see if they are within a clickable element
      let target = ev.target as HTMLElement | null
      while (target != null) {
        const clickable = target.getAttribute('data-clickable')
        switch (clickable) {
          case 'true':
            onRowClick?.(toJS(row), ev)
            return
          case 'false':
            return
        }

        target = target.parentElement
      }

      if (cell == null || ev.currentTarget === ev.target) {
        onRowClick?.(toJS(row), ev)
      }
    },
    [cell, row, onRowClick],
  )

  return (
    <BodyCell role="cell" baseWidth={width} dynamicWidth={!disableResizing} onClick={handleCellClick}>
      {cell}
    </BodyCell>
  )
})
