import { useTheme } from '@emotion/react'
import { AnimatePresence, motion } from 'framer-motion'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useMemo } from 'react'
import { Draggable, DraggableProvided, DraggableStateSnapshot, Droppable, DroppableProvided } from 'react-beautiful-dnd'
import { useToggle } from 'react-use'
import { TABLE_ROW_DRAGGABLE_TYPE } from './constants'
import { RowGroupHeader } from './RowGroupHeader'
import { RowGroupContainer } from './styles'
import { TableBodyRow } from './TableBodyRow'
import { useTableContext } from './TableContext'
import { TableRowGroup } from './types'
import { useObservedValue } from './useObservedValue'

export type RowGroupProps = {
  rowGroup: TableRowGroup<unknown>
  rowGroupIndex: number
  level?: number
}

export const RowGroup = observer(function RowGroup({ rowGroup, rowGroupIndex, level = 0 }: RowGroupProps) {
  const theme = useTheme()
  const {
    props: { draggable, onChangeGroupExpanded, getRowOffset = (n) => 4 + 2 * n, rowGroupHeaderStyles },
  } = useTableContext()
  const [expanded, toggleExpanded] = useToggle(rowGroup.expandedInitially ?? true)

  const groupsDraggable = draggable === true || draggable === 'row-groups'

  const handleToggleExpanded = useCallback(() => {
    toggleExpanded()

    onChangeGroupExpanded?.({
      id: rowGroup.id,
      expanded: !expanded,
    })
  }, [rowGroup.id, expanded, onChangeGroupExpanded, toggleExpanded])

  const renderChildren = useCallback(
    (
      { innerRef, draggableProps, dragHandleProps }: DraggableProvided,
      { isDragging, isDropAnimating }: DraggableStateSnapshot,
    ) => (
      <RowGroupContainer
        {...draggableProps}
        role="rowgroup"
        ref={innerRef}
        animate={{
          background: isDragging ? 'white' : undefined,
          boxShadow: isDragging && !isDropAnimating ? theme.shadows.default : theme.shadows.defaultTransparent,
        }}
      >
        <RowGroupHeader
          sx={{ pl: getRowOffset(level), ...rowGroupHeaderStyles }}
          rowGroup={rowGroup}
          dragHandleProps={dragHandleProps}
          isDragging={isDragging}
          expanded={expanded}
          onToggleExpanded={handleToggleExpanded}
        />
        <RowGroupSubRows rowGroup={rowGroup} expanded={rowGroup.nonCollapsible || expanded} level={level} />
      </RowGroupContainer>
    ),
    [expanded, getRowOffset, handleToggleExpanded, level, rowGroup, theme, rowGroupHeaderStyles],
  )

  if (rowGroup.hideTitle) {
    return (
      <RowGroupContainer role="rowgroup">
        <RowGroupSubRows rowGroup={rowGroup} expanded level={level} />
      </RowGroupContainer>
    )
  } else {
    return (
      <Draggable draggableId={rowGroup.id} index={rowGroupIndex} isDragDisabled={!groupsDraggable}>
        {renderChildren}
      </Draggable>
    )
  }
})

// RowGroupSubRows has curciular dependency on RowGroup

export type RowGroupSubRowsProps = {
  rowGroup: TableRowGroup<unknown>
  expanded: boolean
  level: number
}

export const RowGroupSubRows = observer(function RowGroupSubRows({ rowGroup, expanded, level }: RowGroupSubRowsProps) {
  const {
    props: { renderRowGroupFooter, renderRowGroupExtraHeader, renderRowGroupEmptyPlaceholder },
  } = useTableContext()

  const rowGroupJs = useObservedValue(rowGroup)

  const extraHeader = useMemo(() => renderRowGroupExtraHeader?.(rowGroupJs), [renderRowGroupExtraHeader, rowGroupJs])
  const extraFooter = useMemo(() => renderRowGroupFooter?.(rowGroupJs), [renderRowGroupFooter, rowGroupJs])
  const emptyPlaceholder = useMemo(
    () => !rowGroupJs.rows?.length && !rowGroupJs.groups?.length && renderRowGroupEmptyPlaceholder?.(rowGroupJs),
    [renderRowGroupEmptyPlaceholder, rowGroupJs],
  )

  const renderChildren = useCallback(
    ({ innerRef, droppableProps, placeholder }: DroppableProvided) => (
      <div {...droppableProps} ref={innerRef}>
        <RowGroupSubRowsBody rowGroup={rowGroup} level={level} />

        {placeholder}
        {emptyPlaceholder}
      </div>
    ),
    [rowGroup, level, emptyPlaceholder],
  )

  return (
    <AnimatePresence initial={false}>
      {expanded && (
        <motion.div
          variants={animationVariants}
          initial="hidden"
          animate="shown"
          exit="hidden"
          transition={animationTransition}
        >
          <div>
            {rowGroup.hideGroupHeader !== true && extraHeader}
            {rowGroup.render ? (
              rowGroup.render()
            ) : (
              <Droppable droppableId={rowGroup.id} type={TABLE_ROW_DRAGGABLE_TYPE}>
                {renderChildren}
              </Droppable>
            )}
            {rowGroup.hideGroupFooter !== true && extraFooter}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  )
})

export const animationVariants = {
  shown: {
    height: 'auto',
    opacity: 1,
    transitionEnd: {
      overflow: 'unset',
    },
  },
  hidden: {
    height: 0,
    opacity: 0,
    overflow: 'hidden',
  },
}

export const animationTransition = { type: 'tween' }

type RowGroupSubRowsBodyProps = {
  rowGroup: TableRowGroup<unknown>
  level: number
}

const RowGroupSubRowsBody = observer(function RowGroupSubRowsBody({ rowGroup, level }: RowGroupSubRowsBodyProps) {
  const {
    props: { getRowId },
  } = useTableContext()
  return (
    <>
      {rowGroup.groups?.map((group, index) => (
        <RowGroup key={group.id} rowGroup={group} rowGroupIndex={index} level={level + 1} />
      ))}
      {rowGroup.rows?.map((row, index) => (
        <TableBodyRow key={getRowId(row)} row={row} rowIndex={index} level={level} />
      ))}
    </>
  )
})
