import { useState } from 'react'
import { useDebounce } from 'react-use'

export const useDebouncedValue = <T>(value: T, delay: number, equalsFn?: (lhs: T, rhs: T) => boolean) => {
  const [debouncedValue, setDebouncedValue] = useState(value)
  useDebounce(
    () => {
      setDebouncedValue((currentValue) => (equalsFn?.(currentValue, value) ? currentValue : value))
    },
    delay,
    [value, equalsFn],
  )
  return debouncedValue
}
