import { autorun, toJS } from 'mobx'
import { useEffect, useState } from 'react'

export function useObservedValue<T>(observable: T): T {
  const [observedValue, setObservedValue] = useState(() => toJS(observable))
  useEffect(
    () =>
      autorun(() => {
        setObservedValue(toJS(observable))
      }),
    [observable],
  )
  return observedValue
}
