import React from 'react'
import { IconBase, OptionalIconBaseProps } from '../components/IconBase'

export const RowMenuIcon = (props: OptionalIconBaseProps) => {
  return (
    <IconBase name="row-menu" viewBox="0 0 20 20" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3332 5.00001C12.4127 5.00001 11.6665 4.25381 11.6665 3.33334C11.6665 2.41286 12.4127 1.66667 13.3332 1.66667C14.2536 1.66667 14.9998 2.41286 14.9998 3.33334C14.9998 4.25381 14.2536 5.00001 13.3332 5.00001ZM13.3332 11.6667C12.4127 11.6667 11.6665 10.9205 11.6665 9.99999C11.6665 9.07952 12.4127 8.33333 13.3332 8.33333C14.2536 8.33333 14.9998 9.07952 14.9998 9.99999C14.9998 10.9205 14.2536 11.6667 13.3332 11.6667ZM11.6665 16.6667C11.6665 17.5871 12.4127 18.3333 13.3332 18.3333C14.2536 18.3333 14.9998 17.5871 14.9998 16.6667C14.9998 15.7462 14.2536 15 13.3332 15C12.4127 15 11.6665 15.7462 11.6665 16.6667ZM6.66667 11.6667C5.74619 11.6667 5 10.9205 5 9.99999C5 9.07952 5.74619 8.33333 6.66667 8.33333C7.58714 8.33333 8.33333 9.07952 8.33333 9.99999C8.33333 10.9205 7.58714 11.6667 6.66667 11.6667ZM5 3.33334C5 4.25381 5.74619 5.00001 6.66667 5.00001C7.58714 5.00001 8.33333 4.25381 8.33333 3.33334C8.33333 2.41286 7.58714 1.66667 6.66667 1.66667C5.74619 1.66667 5 2.41286 5 3.33334ZM6.66667 18.3333C5.74619 18.3333 5 17.5871 5 16.6667C5 15.7462 5.74619 15 6.66667 15C7.58714 15 8.33333 15.7462 8.33333 16.6667C8.33333 17.5871 7.58714 18.3333 6.66667 18.3333Z"
      />
    </IconBase>
  )
}
