import { DiffContext, DiffPatcher, Filter } from 'jsondiffpatch'
import { observable, runInAction, toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { TableContent } from './TableContent'
import { TableContextProvider } from './TableContext'
import { TableProps, TableRowGroup } from './types'

const diffPatcher = new DiffPatcher({
  objectHash: (item: Record<string, unknown>, index: number) =>
    typeof item['__typename'] === 'string' && typeof item['id'] === 'string'
      ? `${item['__typename']}:${item['id']}`
      : `${index}`,
})

const functionsDiffFilter: Filter<DiffContext> = (context) => {
  // assume different functions always have different behavior
  if (context.left !== context.right && (context.left instanceof Function || context.right instanceof Function)) {
    context.setResult([context.left, context.right]).exit()
  }
}
functionsDiffFilter.filterName = 'functions'
diffPatcher.processor.pipes.diff.before('trivial', functionsDiffFilter)

const PureTable = <D extends object, G extends TableRowGroup<D> = TableRowGroup<D>>({
  data,
  ...rest
}: TableProps<D, G>) => {
  const [observableProps] = useState(() =>
    observable(
      {
        ...rest,
        data,
      },
      {
        data: observable,
      },
      { deep: false },
    ),
  )

  useEffect(() => {
    const delta = diffPatcher.diff(toJS(observableProps.data), data)

    if (delta) {
      runInAction(() => {
        diffPatcher.patch(observableProps.data, delta)
      })
    }
  }, [observableProps, data])

  useEffect(() => {
    runInAction(() => {
      Object.assign(observableProps, rest)
    })
  })

  const [contextValue] = useState({
    props: observableProps,
  })

  return (
    <TableContextProvider value={contextValue as never}>
      <TableContent />
    </TableContextProvider>
  )
}

export const Table = observer(PureTable) as typeof PureTable
