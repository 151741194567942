import React from 'react'
import { Text, TextProps } from '../Text'

export type TextCellProps = {
  children?: React.ReactNode
  textProps?: TextProps
}

export const TextCell = ({ children, textProps }: TextCellProps) => {
  return (
    <Text overflow="ellipsis" data-clickable="true" {...textProps}>
      {children}
    </Text>
  )
}
