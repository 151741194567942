import { UserSelect, UserSelectProps } from '@mm/company-ui'
import _ from 'lodash'
import React, { useState } from 'react'
import {
  MeetingParticipantsDocument,
  ProfileDataFragment,
  UserSelectCompanyMembersDocument,
} from '../../gen/graphql/documents'
import { OperationVariables, TypedDocumentNode, useQuery } from '../apollo'
import { useActiveCompanyId } from '../companies'
import { useFeatureFlags } from '../featureFlags'
import { InviteParticipantModal } from '../topics/components/InviteParticipantModal'

type UserSelectWithQueryProps<T, V> = Omit<UserSelectProps, 'options'> & {
  query: TypedDocumentNode<T, V>
  variables: V
  extractPage: (data: T) =>
    | Array<{
        node: ProfileDataFragment
      }>
    | null
    | undefined
}

export function UserSelectWithQuery<T, V extends OperationVariables>({
  query,
  variables,
  extractPage,
  ...rest
}: UserSelectWithQueryProps<T, V>) {
  const { data } = useQuery(query, {
    variables,
  })

  const users = (data && extractPage(data)) || []

  return <UserSelect options={users} {...rest} />
}

export function UserSelectWithCompanyMembers(props: Omit<UserSelectProps, 'options'>) {
  const { activeCompanyId: companyId } = useActiveCompanyId()

  return (
    <UserSelectWithQuery
      query={UserSelectCompanyMembersDocument}
      variables={{ companyId }}
      extractPage={(data) => data.company?.members.edges}
      {...props}
    />
  )
}

export function UserSelectWithMeetingParticipants({
  meetingId,
  ...restProps
}: Omit<UserSelectProps, 'options'> & { meetingId: string }) {
  const { activeCompanyId: companyId } = useActiveCompanyId()
  const { showMeetingParticipantsFirst } = useFeatureFlags()
  const [pendingParticipant, setPendingParticipant] = useState<string | undefined>(undefined)

  const { data: meetingData } = useQuery(MeetingParticipantsDocument, {
    variables: {
      id: meetingId,
    },
  })

  const participants = meetingData?.meeting?.participants?.edges.map((o) => o.node.id) ?? []

  return (
    <>
      <InviteParticipantModal
        isOpen={pendingParticipant !== undefined}
        onRequestClose={() => {
          setPendingParticipant(undefined)
        }}
        meetingId={meetingId}
        userId={pendingParticipant}
      />
      <UserSelectWithQuery
        query={UserSelectCompanyMembersDocument}
        variables={{ companyId }}
        extractPage={(data) =>
          _(data.company?.members.edges)
            .map((edge) => {
              const isParticipant = participants.includes(edge.node.id)

              return {
                ...edge,
                isParticipant,
                detail: isParticipant ? undefined : 'Not a participant',
              }
            })
            .orderBy((edge) => (showMeetingParticipantsFirst && edge.isParticipant ? 0 : 1))
            .value()
        }
        {...restProps}
        onAdd={(value) => {
          restProps.onAdd?.(value)

          if (!participants.includes(value)) {
            setPendingParticipant(value)
          }
        }}
      />
    </>
  )
}
