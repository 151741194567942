import { observer } from 'mobx-react-lite'
import React from 'react'
import { TableBodyCell } from './TableBodyCell'
import { useTableContext } from './TableContext'

export type TableBodyRowCellsProps = {
  row: unknown
}

export const TableBodyRowCells = observer(function TableBodyRowsCells({ row }: TableBodyRowCellsProps) {
  const {
    props: { columns },
  } = useTableContext()

  return (
    <>
      {columns.map((column, index) => (
        <TableBodyCell column={column} row={row} key={index} />
      ))}
    </>
  )
})
