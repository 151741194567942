import React from 'react'
import { IconBase, OptionalIconBaseProps } from '../components/IconBase'

export const ChevronIcon = (props: OptionalIconBaseProps) => (
  <IconBase name="chevron" viewBox="0 0 16 16" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.52861 5.52859C3.78896 5.26824 4.21107 5.26824 4.47141 5.52859L8.00001 9.05719L11.5286 5.52859C11.789 5.26824 12.2111 5.26824 12.4714 5.52859C12.7318 5.78894 12.7318 6.21105 12.4714 6.4714L8.47141 10.4714C8.21107 10.7317 7.78896 10.7317 7.52861 10.4714L3.52861 6.4714C3.26826 6.21105 3.26826 5.78894 3.52861 5.52859Z"
    />
  </IconBase>
)
