import { ExpandCollapseAnimatedIcon, SearchIcon } from '@mm/company-ui-icons'
import React, { useRef } from 'react'
import { useSearchField } from 'react-aria'
import { SearchFieldProps, useSearchFieldState } from 'react-stately'
import { useMergeRefs } from '../../../hooks'
import { Button } from '../../Button'
import { Flex } from '../../Flex'

export type UserSelectSearchFieldProps = SearchFieldProps & {
  type?: string
  open?: boolean
  showOpenButton?: boolean
  onOpenToggle?: () => void
}

export const UserSelectSearchField = React.forwardRef<HTMLInputElement, UserSelectSearchFieldProps>(
  ({ open, showOpenButton, onOpenToggle, ...rest }, ref) => {
    const searchState = useSearchFieldState(rest)
    const inputRef = useRef<HTMLInputElement>(null)
    const { inputProps } = useSearchField(rest, searchState, inputRef)
    const mergedRefs = useMergeRefs([inputRef, ref])

    return (
      <Flex
        row
        align="center"
        gap={1}
        sx={{
          pl: 2,
          pr: showOpenButton ? 1 : 2,
          py: 1,
          bg: 'background-light',
          borderBottom: '1px solid',
          borderBottomColor: 'border-medium',
        }}
      >
        <SearchIcon display="block" color="text-light" />
        <input
          {...inputProps}
          placeholder="Select user..."
          ref={mergedRefs}
          sx={{
            height: 3,
            flexGrow: 1,
            fontSize: 1,
            lineHeight: 1.5,
            fontFamily: 'body',
            border: 'none',
            outline: 'none',
            bg: 'transparent',
            '&::placeholder': {
              color: 'text-medium',
            },
          }}
        />
        {showOpenButton && (
          <Button
            variant="muted"
            size="small"
            startIcon={<ExpandCollapseAnimatedIcon expanded={!!open} />}
            onClick={onOpenToggle}
            textHidden
            sx={{ width: '24px', height: '24px' }} // TODO T-2703
          >
            {open ? 'Collapse' : 'Expand'}
          </Button>
        )}
      </Flex>
    )
  },
)

UserSelectSearchField.defaultProps = {
  autoFocus: true,
  type: 'text',
}

UserSelectSearchField.displayName = 'UserSelectSearchField'
