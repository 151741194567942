import isHotkey from 'is-hotkey'
import React, { useEffect, useRef, useState } from 'react'
import { Input } from '../Input'
import { Text } from '../Text'

export type RowGroupSubtitleProps = {
  value: string
  placeholder?: string
  editable?: boolean
  focus?: boolean
  className?: string
  onChange?: (title: string) => void
}

export const RowGroupSubtitle = ({
  className,
  value,
  placeholder,
  editable,
  focus,
  onChange,
}: RowGroupSubtitleProps) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [currentValue, setCurrentValue] = useState<string>()

  useEffect(() => {
    if (focus) {
      inputRef.current?.focus()
    }
  }, [focus])

  if (editable) {
    return (
      <Input
        ref={inputRef}
        variant="muted"
        value={currentValue ?? value}
        placeholder={placeholder}
        className={className}
        onBlur={() => {
          if (currentValue !== undefined) {
            onChange?.(currentValue)
            setCurrentValue(undefined)
          }
        }}
        onChange={(value) => {
          setCurrentValue(value)
        }}
        onKeyDown={(event) => {
          if (isHotkey(['esc', 'enter'], event)) {
            event.currentTarget.blur()
          }
        }}
        sx={{
          width: 'fit-content', // To avoid the input from taking the full width of the container
          marginLeft: -1, // Negative margin aligns the text of the input with the rest of the content on the page
          input: {
            color: 'text-light',
          },
        }}
      />
    )
  } else {
    return (
      <Text color="text-light" className={className}>
        {value}
      </Text>
    )
  }
}
