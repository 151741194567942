import isPropValid from '@emotion/is-prop-valid'
import styled from '@emotion/styled'
import { isValidMotionProp, motion } from 'framer-motion'
import { css } from 'theme-ui'
import { Flex } from '../Flex'
import { DragHandle } from './DragHandle'

interface CustomStyles {
  [key: string]: any
}

export const DragHandleCell = styled(DragHandle)(({ isDragging, highlight }) =>
  css({
    position: 'absolute',
    left: 0,
    alignSelf: 'center',
    flex: '0 0 auto',

    opacity: isDragging || highlight ? 1 : 0,
  }),
)

export const RowGroupContainer = styled(motion.div, {
  shouldForwardProp: (prop) => isPropValid(prop) || isValidMotionProp(String(prop)),
})(
  css({
    display: 'flex',
    flexDirection: 'column',
    minWidth: '0px',
    flex: '1 0 auto',
  }),
)

export const RowGroupHeaderContainer = styled(Flex)<{ rowStyles?: CustomStyles }>(
  css({
    position: 'relative',
    px: 3,
  }),
  ({ rowStyles = {} }) =>
    css({
      ...rowStyles,
    }),
)
export const baseRowBorderStyle = {
  borderTopColor: 'background-medium-alpha',
  borderTopStyle: 'solid',
  borderTopWidth: '1px',
}
const BaseRow = styled.div<{ noBorders?: boolean; rowStyles?: CustomStyles }>(
  css({
    display: 'flex',
    flex: '1 0 auto',
    minWidth: 'min-content',
    pl: '28px', // Was 4 but overrided to match small button width
    pr: 1,
  }),
  ({ noBorders }) =>
    !noBorders &&
    css({
      ...baseRowBorderStyle,
      '&:first-of-type': {
        borderTop: 'none',
      },
    }),
  ({ rowStyles = {} }) =>
    css({
      ...rowStyles,
    }),
)
export const HeadRow = styled(BaseRow)<{ stickyHeader?: boolean }>(
  ({ stickyHeader }) =>
    stickyHeader &&
    css({
      position: 'sticky',
      top: 0,
      zIndex: 2,
      background: 'white',
      borderRadius: 'default',
    }),
)
export const BodyRow = styled(BaseRow.withComponent(motion.div), {
  shouldForwardProp: (prop) => isPropValid(prop) || isValidMotionProp(String(prop)),
})<{
  highlight?: boolean
  disableHover?: boolean
  selected?: boolean
  rowStyles?: CustomStyles
}>(({ highlight, selected, rowStyles = {} }) =>
  css({
    position: 'relative',
    bg: selected ? 'accent-background-light' : highlight ? 'background-light' : 'transparent',
    ...rowStyles,
  }),
)

const BaseCell = styled.div<{ baseWidth?: number; dynamicWidth: boolean }>(
  css({
    paddingRight: 1,
    minHeight: 4,

    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,
    flexBasis: 'auto',

    '&:last-of-type': {
      paddingRight: 0,
    },
  }),
  (
    { baseWidth = 150, dynamicWidth }, // 150 as default is copied from react-table
  ) =>
    css({
      width: `${baseWidth}px`,
      flexGrow: dynamicWidth && baseWidth,
    }),
)
export const BodyCell = styled(BaseCell)()
export const HeadCell = styled(BaseCell)(
  css({
    minWidth: '0px',
    flexShrink: 0,

    fontFamily: 'body',
    fontWeight: '500',
    fontSize: 0,
    color: 'text-medium',
  }),
)

export const TableRoot = styled.div<{ tableStyles?: CustomStyles; noHeaders?: boolean }>(
  ({ noHeaders }) =>
    css({
      paddingTop: noHeaders ? 1 : 0,
      paddingBottom: 1,
      minWidth: '0px',
      ':not(.dragging)': {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        [`${BodyRow}`]: {
          cursor: 'pointer',
          ':hover': {
            bg: 'background-light',
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            [`${DragHandleCell}`]: {
              opacity: 1,
            },
          },
        },
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        [`${RowGroupHeaderContainer}:hover ${DragHandleCell}`]: {
          opacity: 1,
        },
      },
    }),
  ({ tableStyles = {} }) =>
    css({
      ...tableStyles,
    }),
)
