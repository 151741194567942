import React, { useCallback, useState, createContext, useContext, useMemo } from 'react'
import { DragDropContext, DragDropContextProps, DropResult, ResponderProvided } from 'react-beautiful-dnd'
import { TABLE_ROW_DRAGGABLE_TYPE, TABLE_ROW_GROUP_DRAGGABLE_TYPE } from './constants'

type OnDragEndListener = ((result: DropResult, provided: ResponderProvided) => void) | undefined

type TableDragDropContextProps = Omit<DragDropContextProps, 'onDragEnd' | 'children'> & {
  setOnRowGroupDragEnd?: React.Dispatch<React.SetStateAction<OnDragEndListener>>
  setOnRowDragEnd?: React.Dispatch<React.SetStateAction<OnDragEndListener>>
  draggingSomething?: boolean
}

const TableDragDropContext = createContext<TableDragDropContextProps | null>(null)

export const TableDragDropProvider: React.FC<TableDragDropContextProps> = (props) => {
  const { children } = props
  const [onRowGroupDragEnd, setOnRowGroupDragEnd] = useState<OnDragEndListener>(undefined)
  const [onRowDragEnd, setOnRowDragEnd] = useState<OnDragEndListener>(undefined)

  const [draggingSomething, setDraggingSomething] = useState(false)

  const handleDragStart = useCallback(() => {
    setDraggingSomething(true)
  }, [])

  const handleDragEnd = useCallback(
    (result: DropResult, provided: ResponderProvided) => {
      setDraggingSomething(false)
      switch (result.type) {
        case TABLE_ROW_GROUP_DRAGGABLE_TYPE:
          onRowGroupDragEnd?.(result, provided)
          break
        case TABLE_ROW_DRAGGABLE_TYPE:
          onRowDragEnd?.(result, provided)
          break
      }
    },
    [onRowDragEnd, onRowGroupDragEnd],
  )

  const value = useMemo(
    () => ({
      setOnRowDragEnd,
      setOnRowGroupDragEnd,
      draggingSomething,
    }),
    [setOnRowGroupDragEnd, setOnRowDragEnd, draggingSomething],
  )

  return (
    <TableDragDropContext.Provider value={value}>
      <DragDropContext onDragEnd={handleDragEnd} onDragStart={handleDragStart} {...props}>
        {children}
      </DragDropContext>
    </TableDragDropContext.Provider>
  )
}
export const useTableDragDropContext = () => {
  const context = useContext(TableDragDropContext)
  if (context == null) {
    throw new Error('must be used by descendants of TableDragDropProvider')
  }

  return context
}

export default TableDragDropContext
