import styled from '@emotion/styled'
import { useFocusRing } from '@react-aria/focus'
import { useRadio } from '@react-aria/radio'
import { useId, useObjectRef } from '@react-aria/utils'
import { VisuallyHidden } from '@react-aria/visually-hidden'
import type { AriaRadioProps } from '@react-types/radio'
import type { AriaLabelingProps } from '@react-types/shared'
import React from 'react'
import { css, ThemeUIStyleObject } from 'theme-ui'
import { Flex, FlexOwnProps } from '../Flex'
import { Text } from '../Text'
import { RadioButtonShape, RadioContext } from './context'

export type RadioButtonProps = Omit<AriaRadioProps, keyof AriaLabelingProps> & {
  shape?: RadioButtonShape
  className?: string
  description?: React.ReactNode
  align?: FlexOwnProps['align']
  sx?: ThemeUIStyleObject
}

export const RadioButton = React.forwardRef<HTMLInputElement, RadioButtonProps>(
  ({ shape, className, description, align, children, ...rest }, ref) => {
    const ctx = React.useContext(RadioContext)
    if (ctx == null) {
      throw new Error('Must be descendant of the RadioGroup')
    }
    const { state, props } = ctx

    shape = shape ?? props.shape ?? 'circle'

    const objRef = useObjectRef(ref)
    const labelId = useId()
    const descriptionId = useId()
    const { inputProps } = useRadio(
      {
        ...rest,
        children,
        'aria-labelledby': labelId,
        'aria-describedby': descriptionId,
      },
      state,
      objRef,
    )
    const { isFocusVisible, focusProps } = useFocusRing()

    const disabled = !!inputProps.disabled
    const checked = !!inputProps.checked

    return (
      <Flex as="label" align={align} sx={{ py: 0.5, cursor: disabled ? undefined : 'pointer' }} className={className}>
        <VisuallyHidden>
          <input {...inputProps} {...focusProps} ref={objRef} />
        </VisuallyHidden>
        <IconWrapper
          focusVisible={isFocusVisible}
          disabled={disabled}
          checked={checked}
          data-testid={`RadioButton-icon-${rest.value}`} // there is no good way to find this element since it has aria-hidden
        >
          <svg
            style={{ display: 'block' }}
            width="1.25em"
            height="1.25em"
            viewBox="0 0 20 20"
            fill="currentcolor"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
          >
            <path d="M10 1.66667C5.39763 1.66667 1.66667 5.39763 1.66667 10C1.66667 14.6024 5.39763 18.3333 10 18.3333C14.6024 18.3333 18.3333 14.6024 18.3333 10C18.3333 5.39763 14.6024 1.66667 10 1.66667ZM0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z" />
            {checked && shape === 'circle' && <circle cx="10" cy="10" r="5" />}
            {checked && shape === 'tick' && (
              <path d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17316C0.0043329 8.00043 -0.193701 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8078C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C19.9923 7.3502 18.9363 4.81113 17.0626 2.93743C15.1889 1.06373 12.6498 0.00769496 10 0ZM9.04044 13.8046C8.64992 14.1951 8.01676 14.1951 7.62623 13.8046L4.41084 10.5892C4.08545 10.2638 4.08545 9.73622 4.41084 9.41083C4.73623 9.08544 5.26378 9.08544 5.58917 9.41083L8.33334 12.155L14.4108 6.0775C14.7362 5.75211 15.2638 5.75211 15.5892 6.0775C15.9146 6.40289 15.9146 6.93044 15.5892 7.25583L9.04044 13.8046Z" />
            )}
          </svg>
        </IconWrapper>
        <Flex column gap={0.5}>
          <Text id={labelId}>{children}</Text>
          {description && (
            <Text variant="small" color="text-light" id={descriptionId}>
              {description}
            </Text>
          )}
        </Flex>
      </Flex>
    )
  },
)

RadioButton.displayName = 'RadioButton'
RadioButton.defaultProps = {
  align: 'start',
}

const IconWrapper = styled.div<{ focusVisible: boolean; disabled: boolean; checked: boolean }>(
  css({
    borderRadius: '50%',
    verticalAlign: 'text-bottom',
    marginRight: 1,
    marginY: 0.25,
  }),
  ({ disabled, checked }) =>
    css({
      color: disabled ? 'text-disabled' : checked ? 'accent-text' : 'text-light',
    }),
  ({ focusVisible }) =>
    focusVisible &&
    css({
      outline: '2px solid',
      outlineColor: 'accent-background',
    }),
)
