import _ from 'lodash'
import { useRef } from 'react'

export type Predicate<T> = (prev: T, current: T) => boolean

export const useDistinct = <T>(value: T, compare: Predicate<T> = _.isEqual): T => {
  const ref = useRef<T>(value)

  if (!compare(ref.current, value)) {
    ref.current = value
  }

  return ref.current
}
