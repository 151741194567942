import { useCheckboxGroup } from '@react-aria/checkbox'
import { useCheckboxGroupState } from '@react-stately/checkbox'
import type { AriaCheckboxGroupProps } from '@react-types/checkbox'
import type { ValueBase } from '@react-types/shared'
import React from 'react'
import { ThemeUIStyleObject } from 'theme-ui'
import type { Merge } from 'type-fest'
import { CheckboxContext } from './context'

export type CheckboxGroupProps<T extends string = string> = Merge<AriaCheckboxGroupProps, ValueBase<T[]>> & {
  className?: string
  children?: React.ReactNode
  sx?: ThemeUIStyleObject
}

export const CheckboxGroup = <T extends string>({ children, className, ...props }: CheckboxGroupProps<T>) => {
  const state = useCheckboxGroupState(props as AriaCheckboxGroupProps)
  const { groupProps, labelProps } = useCheckboxGroup(props as AriaCheckboxGroupProps, state)

  return (
    <div {...groupProps} className={className}>
      {props.label && <span {...labelProps}>{props.label}</span>}
      <CheckboxContext.Provider value={state}>{children}</CheckboxContext.Provider>
    </div>
  )
}
