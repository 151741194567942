import Tippy, { TippyProps } from '@tippyjs/react/headless'
import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import type { Instance, Plugin } from 'tippy.js'
import { ZIndexLayer } from '../../ZIndexLayer'
import { Backdrop } from '../Drawer'
import { PopupReference, PopupWrapper } from '../Popup'

export type FlyoutProps = Omit<React.ComponentPropsWithoutRef<'div'>, keyof FlyoutOwnProps> & FlyoutOwnProps

export type FlyoutOwnProps = {
  trigger?: 'click' | 'mouseenter'
  content?: React.ReactNode | ((instance: Instance) => React.ReactNode)
  contentWrapperProps?: React.ComponentPropsWithRef<typeof PopupWrapper>
  position?: TippyProps['placement']
  tooltip?: React.ReactNode
  tooltipWrapperProps?: React.ComponentPropsWithRef<typeof PopupWrapper>
  tooltipPosition?: TippyProps['placement']
  disabled?: boolean
  visible?: boolean
  backdrop?: boolean
  children?: React.ReactElement | React.ReactElement[]
  onClickOutside?: () => void
  onShow?: () => void
  onHide?: () => void
  onTooltipShow?: (instance: Instance) => void | false
  appendTo?: Element
}

const hideOnEsc: Plugin = {
  name: 'hideOnEsc',
  defaultValue: true,
  fn({ hide }) {
    function onKeyDown(e: KeyboardEvent) {
      if (e.key === 'Escape' || e.key == 'Esc') {
        hide()
      }
    }

    return {
      onShow() {
        document.addEventListener('keydown', onKeyDown)
      },
      onHide() {
        document.removeEventListener('keydown', onKeyDown)
      },
    }
  },
}

export const Flyout = ({
  disabled,
  backdrop = true,
  visible = undefined,
  position = 'auto',
  trigger = 'click',
  tooltip,
  tooltipWrapperProps,
  tooltipPosition = 'auto',
  content,
  contentWrapperProps,
  children,
  onShow,
  onHide,
  onClickOutside,
  onTooltipShow,
  appendTo,
  ...props
}: FlyoutProps) => {
  const [flyoutVisible, setFlyoutVisible] = useState(false)

  children = <PopupReference {...props}>{children}</PopupReference>

  if (trigger === 'click' && tooltip) {
    children = (
      <Tippy
        arrow={false}
        offset={[0, 12]}
        placement={tooltipPosition}
        disabled={disabled || flyoutVisible}
        visible={visible}
        onShow={onTooltipShow}
        render={(attrs) => (
          <PopupWrapper {...attrs} {...tooltipWrapperProps} sx={{ fontSize: 0, ...tooltipWrapperProps?.sx }}>
            {tooltip}
          </PopupWrapper>
        )}
        appendTo={appendTo}
      >
        {children}
      </Tippy>
    )
  }

  const bd = <Backdrop backgroundColor="transparent" zIndex={ZIndexLayer.LAYER_3_POPOVER} />

  return (
    <>
      {backdrop && flyoutVisible && (appendTo ? ReactDOM.createPortal(bd, appendTo) : bd)}
      <Tippy
        arrow={false}
        offset={[0, 12]}
        trigger={visible != null ? undefined : trigger}
        visible={visible}
        interactive={true}
        placement={position}
        disabled={disabled}
        render={(attrs, _, instance) => (
          <PopupWrapper {...attrs} {...contentWrapperProps} sx={{ px: 0, py: 1, ...contentWrapperProps?.sx }}>
            {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
            {typeof content === 'function' ? content(instance!) : content}
          </PopupWrapper>
        )}
        appendTo={appendTo}
        onShow={() => {
          setFlyoutVisible(true)
          onShow?.()
        }}
        onHide={onHide}
        onHidden={() => {
          setFlyoutVisible(false)
        }}
        onClickOutside={onClickOutside}
        plugins={[hideOnEsc]}
      >
        {children}
      </Tippy>
    </>
  )
}
