import { useTheme } from '@emotion/react'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import React, { MouseEventHandler, useCallback, useMemo, useState } from 'react'
import { Draggable, DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd'
import { ContextMenu } from '../FloatingUI'
import { PopupWrapper } from '../Popup'
import { BodyRow, DragHandleCell } from './styles'
import { TableBodyRowCells } from './TableBodyRowCells'
import { useTableContext } from './TableContext'
import { useObservedValue } from './useObservedValue'

export type TableBodyRowProps = {
  row: unknown
  rowIndex: number
  level: number
}

export const TableBodyRow = observer(function TableBodyRow({ row, rowIndex, level }: TableBodyRowProps) {
  const theme = useTheme()
  const {
    props: {
      noBorders,
      rowStyles,
      draggable,
      decorateRow,
      onRowClick,
      renderRowMenu,
      noDragHandle,
      getRowId,
      getRowOffset = (n) => 4 + 2 * n,
    },
  } = useTableContext()

  const rowsDraggable = draggable === true || draggable === 'rows'

  const handleRowClick = useCallback<MouseEventHandler<HTMLDivElement>>(
    (ev) => {
      if (ev.currentTarget === ev.target) {
        onRowClick?.(toJS(row), ev)
      }
    },
    [row, onRowClick],
  )

  const rowJs = useObservedValue(row) as { [key: string]: any }
  const isSelected = rowJs['selected'] as boolean
  const rowId = getRowId(row)

  const menu = useMemo(() => renderRowMenu?.(rowJs), [renderRowMenu, rowJs])

  const [menuShown, setMenuShown] = useState(false)

  const renderChildren = useCallback(
    (
      { dragHandleProps, innerRef, draggableProps }: DraggableProvided,
      { isDragging, isDropAnimating }: DraggableStateSnapshot,
    ) => {
      const wrapWithContextMenu = (render: (props?: Record<string, unknown>) => React.ReactElement) => {
        if (menu == null) return render()
        return (
          <ContextMenu
            render={() => <PopupWrapper sx={{ px: 0, py: 1 }}>{menu}</PopupWrapper>}
            onOpenChange={setMenuShown}
          >
            {({ getReferenceProps }) => render(getReferenceProps())}
          </ContextMenu>
        )
      }

      const children = wrapWithContextMenu((extraProps) => (
        <BodyRow
          {...draggableProps}
          role="row"
          data-row-id={rowId}
          ref={innerRef}
          data-persist-drawer="true"
          onClick={handleRowClick}
          highlight={isDragging || menuShown}
          selected={isSelected}
          noBorders={noBorders}
          rowStyles={{ paddingLeft: getRowOffset(level), ...rowStyles }}
          animate={{
            boxShadow: isDragging && !isDropAnimating ? theme.shadows.default : theme.shadows.defaultTransparent,
          }}
          {...extraProps}
        >
          {!noDragHandle && (
            <DragHandleCell
              menu={menu}
              dragHandleProps={dragHandleProps}
              draggable={rowsDraggable}
              isDragging={isDragging}
              onMenuVisibilityChange={setMenuShown}
              highlight={menuShown}
            />
          )}
          <TableBodyRowCells row={row} />
        </BodyRow>
      ))
      return decorateRow ? decorateRow(children, rowJs) : children
    },
    [
      rowId,
      getRowOffset,
      level,
      handleRowClick,
      menuShown,
      isSelected,
      noBorders,
      rowStyles,
      theme,
      menu,
      rowsDraggable,
      noDragHandle,
      row,
      decorateRow,
      rowJs,
    ],
  )

  return (
    <Draggable draggableId={rowId} index={rowIndex} isDragDisabled={!rowsDraggable}>
      {renderChildren}
    </Draggable>
  )
})
