import React from 'react'
import { IconBase, OptionalIconBaseProps } from '../components/IconBase'

export const MeetingTeamsGroupsUsersIcon = (props: OptionalIconBaseProps) => {
  return (
    <IconBase name="meeting-teams-groups-users" viewBox="0 0 20 20" {...props}>
      <path d="M11.18 1.17003C11.8152 0.796508 12.5371 0.599209 13.2728 0.599251C14.0086 0.599293 14.7304 0.796675 15.3656 1.17027C16.0006 1.54378 16.5258 2.07976 16.8901 2.72271C17.2543 3.3655 17.4454 4.09337 17.4453 4.83333V6.5L17.4453 6.50193C17.4437 7.23955 17.2515 7.96466 16.8868 8.60448C16.6114 9.08754 16.2448 9.50958 15.8089 9.84687V10.0594L18.8244 11.2905C19.145 11.4211 19.4176 11.6455 19.6081 11.9319C19.7987 12.2182 19.8996 12.5543 19.8998 12.8969V17.5C19.8998 17.9971 19.4969 18.4 18.9998 18.4C18.5027 18.4 18.0998 17.9971 18.0998 17.5V12.939L15.0844 11.7078L15.0834 11.7074C14.7636 11.577 14.492 11.3534 14.3016 11.0679C14.1111 10.7823 14.0098 10.4469 14.009 10.1048L14.009 10.1025V9.37083C14.009 9.05101 14.1787 8.75522 14.4548 8.59383C14.8133 8.3843 15.1133 8.08111 15.323 7.71312C15.5327 7.34532 15.6442 6.92657 15.6453 6.49908V4.83333C15.6454 4.40299 15.5342 3.98092 15.3241 3.61009C15.114 3.23937 14.813 2.93356 14.453 2.72177C14.093 2.51007 13.686 2.39927 13.2727 2.39925C12.8594 2.39923 12.4524 2.50997 12.0924 2.72164C11.664 2.9736 11.1124 2.83051 10.8604 2.40204C10.6085 1.97357 10.7516 1.42198 11.18 1.17003ZM15.7628 10.0405L15.7638 10.0409L15.7628 10.0405Z" />
      <path d="M6.87288 1.43333C7.98494 1.43333 9.04804 1.88343 9.82925 2.67911C10.6099 3.47423 11.0456 4.54919 11.0456 5.66667C11.0456 6.23426 11.3376 6.88606 11.7168 7.46544C11.8971 7.74093 12.0787 7.9722 12.2149 8.13404C12.5404 8.52081 12.8549 8.934 12.5571 9.45818C12.4647 9.62078 12.2078 9.99423 11.6709 10.3766C11.2788 10.6558 10.7486 10.9336 10.0397 11.1501L12.4247 12.1239C12.7454 12.2544 13.0179 12.4788 13.2085 12.7652C13.399 13.0515 13.4999 13.3876 13.5001 13.7303L13.5001 17.5C13.5001 17.9971 13.0972 18.4 12.6001 18.4C12.1031 18.4 11.7001 17.9971 11.7001 17.5V13.7723L8.68369 12.5407C8.36385 12.4104 8.09226 12.1868 7.90185 11.9012C7.71211 11.6167 7.60973 11.2777 7.60924 10.9358V10.56C7.60924 10.1099 7.94182 9.72893 8.38786 9.66822C9.53356 9.51228 10.2003 9.19702 10.5654 8.95273C10.4531 8.80509 10.3321 8.63659 10.2108 8.45123C9.7718 7.78061 9.2456 6.76574 9.2456 5.66667C9.2456 5.01603 8.99168 4.3953 8.54484 3.94017C8.09854 3.48561 7.49678 3.23333 6.87288 3.23333C6.20446 3.23333 5.57556 3.45664 5.10081 3.94017C4.65397 4.3953 4.40005 5.01603 4.40005 5.66667C4.40005 6.76574 3.87385 7.78061 3.4349 8.45123C3.31357 8.63659 3.19259 8.80509 3.08026 8.95273C3.44538 9.19702 4.11209 9.51228 5.25779 9.66822C5.70383 9.72893 6.03641 10.1099 6.03641 10.56V10.9358C6.03592 11.2777 5.93354 11.6167 5.7438 11.9012C5.55339 12.1868 5.2818 12.4104 4.96196 12.5407L1.94551 13.7723V17.5C1.94551 17.9971 1.54256 18.4 1.04551 18.4C0.548451 18.4 0.145508 17.9971 0.145508 17.5V13.7303C0.145721 13.3876 0.246644 13.0515 0.437164 12.7652C0.627734 12.4788 0.900291 12.2544 1.22093 12.1239L3.60591 11.1501C2.89701 10.9336 2.36688 10.6558 1.9747 10.3766C1.66746 10.1578 1.45231 9.94324 1.30635 9.76743C0.936771 9.32226 0.843662 8.78283 1.27046 8.31709C1.38225 8.1951 1.65887 7.87788 1.92884 7.46544C2.30807 6.88606 2.60005 6.23426 2.60005 5.66667C2.60005 4.54919 3.03574 3.47423 3.8164 2.67911C4.62767 1.85281 5.72375 1.43333 6.87288 1.43333Z" />
    </IconBase>
  )
}
