import { useTheme } from '@emotion/react'
import { ChevronBoldIcon } from '@mm/company-ui-icons'
import { AnimatePresence, motion } from 'framer-motion'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import React, { useMemo, useState } from 'react'
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd'
import { Button } from '../Button'
import { Flex } from '../Flex'
import { animationTransition, animationVariants } from './RowGroup'
import { RowGroupSubtitle } from './RowGroupSubtitle'
import { RowGroupTitle } from './RowGroupTitle'
import { DragHandleCell, RowGroupHeaderContainer } from './styles'
import { useTableContext } from './TableContext'
import { RowGroupHeaderToggleProps, TableRowGroup } from './types'
import { useObservedValue } from './useObservedValue'

export type RowGroupHeaderTitleProps = {
  rowGroup: TableRowGroup<unknown>
  className?: string
  onClick?: () => void
  expanded?: boolean
}

export const RowGroupHeaderTitle = ({ rowGroup, className, onClick, expanded = true }: RowGroupHeaderTitleProps) => {
  const {
    props: { onRowGroupTitleChange, onRowGroupSubtitleChange },
  } = useTableContext()

  return (
    <Flex column onClick={onClick} sx={{ flex: 1, margin: -1, padding: 1 }}>
      <RowGroupTitle
        className={className}
        focus={rowGroup.focusTitleIfEmpty && !rowGroup.title}
        value={rowGroup.title}
        placeholder={rowGroup.titlePlaceholder}
        editable={rowGroup.titleEditable}
        onChange={(title) => {
          onRowGroupTitleChange?.(toJS(rowGroup), title)
        }}
      />
      <AnimatePresence>
        {rowGroup.subtitle && expanded ? (
          <motion.div
            variants={animationVariants}
            initial="hidden"
            animate="shown"
            exit="hidden"
            transition={animationTransition}
          >
            <RowGroupSubtitle
              focus={rowGroup.focusSubtitleIfEmpty && !rowGroup.subtitle}
              value={rowGroup.subtitle}
              placeholder={rowGroup.subtitlePlaceholder}
              editable={rowGroup.subtitleEditable}
              onChange={(subtitle) => {
                onRowGroupSubtitleChange?.(toJS(rowGroup), subtitle)
              }}
            />
          </motion.div>
        ) : null}
      </AnimatePresence>
    </Flex>
  )
}

export const RowGroupHeaderToggle = ({ onToggleExpanded, expanded }: RowGroupHeaderToggleProps) => {
  const theme = useTheme()

  return (
    <Flex justify="flex-end" sx={{ marginRight: -1 }}>
      <Button
        size="small"
        variant="muted"
        onClick={onToggleExpanded}
        startIcon={
          <ChevronBoldIcon
            display="block"
            sx={{
              transition: theme.transition.default,
              transform: `rotate(${expanded ? -180 : 0}deg)`,
            }}
          />
        }
        textHidden
      >
        {expanded ? 'Collapse group' : 'Expand group'}
      </Button>
    </Flex>
  )
}

const renderDefaultRowGroupHeader = (rowGroup: TableRowGroup<unknown>, toggleProps: RowGroupHeaderToggleProps) => (
  <>
    <RowGroupHeaderTitle rowGroup={rowGroup} expanded={toggleProps.expanded} />
    {!rowGroup.nonCollapsible && <RowGroupHeaderToggle {...toggleProps} />}
  </>
)

export type RowGroupHeaderProps = {
  rowGroup: TableRowGroup<unknown>
  isDragging: boolean
  expanded: boolean
  dragHandleProps?: DraggableProvidedDragHandleProps

  onToggleExpanded?: () => void

  className?: string
}

export const RowGroupHeader = observer(function RowGroupHeader({
  rowGroup,
  isDragging,
  expanded,
  dragHandleProps,
  onToggleExpanded,
  className,
}: RowGroupHeaderProps) {
  const {
    props: { renderRowGroupMenu, renderRowGroupHeader = renderDefaultRowGroupHeader, draggable },
  } = useTableContext()

  const rowGroupJs = useObservedValue(rowGroup)

  const menu = useMemo(() => renderRowGroupMenu?.(rowGroupJs), [renderRowGroupMenu, rowGroupJs])
  const header = useMemo(
    () => renderRowGroupHeader(rowGroupJs, { expanded, onToggleExpanded }),
    [expanded, onToggleExpanded, renderRowGroupHeader, rowGroupJs],
  )

  const [menuShown, setMenuShown] = useState(false)

  return (
    <RowGroupHeaderContainer className={className} role="rowheader" row align="center" gap={1}>
      <DragHandleCell
        menu={menu}
        dragHandleProps={dragHandleProps}
        draggable={draggable === true || draggable === 'row-groups'}
        isDragging={isDragging}
        onMenuVisibilityChange={setMenuShown}
        highlight={menuShown}
      />
      {header}
    </RowGroupHeaderContainer>
  )
})
