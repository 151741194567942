import { RadioGroupState } from '@react-stately/radio'
import React from 'react'

export type RadioContextValue = {
  state: RadioGroupState
  props: RadioContextProps
}

export type RadioButtonShape = 'circle' | 'tick'

export type RadioContextProps = {
  shape?: RadioButtonShape
}

export const RadioContext = React.createContext<RadioContextValue | null>(null)
