import React from 'react'
import { IconBase, OptionalIconBaseProps } from '../components/IconBase'

export const ChevronBoldIcon = (props: OptionalIconBaseProps) => (
  <IconBase name="chevronBold" viewBox="0 0 16 16" {...props}>
    <path
      d="M8 8.50722L4.77175 5.27897C4.56401 5.07125 4.32413 4.96739 4.05208 4.96739C3.78004 4.96739 3.54015 5.07125 3.33242 5.27897C3.12468 5.48671 3.02081 5.72659 3.02081 5.99864C3.02081 6.27068 3.12704 6.51292 3.33948 6.72537L7.27898 10.6649C7.37754 10.7579 7.49019 10.8315 7.61694 10.8859C7.74369 10.9402 7.87277 10.9674 8.00417 10.9674C8.13557 10.9674 8.26465 10.9402 8.3914 10.8859C8.51815 10.8315 8.62802 10.7579 8.72102 10.6649L12.6605 6.72537C12.873 6.51292 12.9792 6.27415 12.9792 6.00905C12.9792 5.74396 12.8753 5.50754 12.6676 5.2998C12.4598 5.09208 12.22 4.98822 11.9479 4.98822C11.6759 4.98822 11.436 5.09208 11.2283 5.2998L8 8.50722Z"
      fill="black"
    />
  </IconBase>
)
