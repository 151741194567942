import { observer } from 'mobx-react-lite'
import React from 'react'
import { RowGroup } from './RowGroup'
import { useTableContext } from './TableContext'

export const RowGroups = observer(() => {
  const {
    props: { data },
  } = useTableContext()

  return (
    <>
      {data.map((group, index) => (
        <RowGroup key={group.id} rowGroup={group} rowGroupIndex={index} />
      ))}
    </>
  )
})
