import { motion } from 'framer-motion'
import React from 'react'
import { IconBase, OptionalIconBaseProps } from '../components/IconBase'

export type ExpandCollapseAnimatedIconProps = OptionalIconBaseProps & {
  expanded: boolean
}

const topVariants = {
  expanded: {
    d: 'M4.66666 13.6285L8.00195 10.2915L11.333 13.6285',
  },
  collapsed: {
    d: 'M4.66666 10.2962L8 13.6285L11.334 10.2962',
  },
}

const bottomVariants = {
  expanded: {
    d: 'M4.66666 2.31641L8 5.65234L11.333 2.32031',
  },
  collapsed: {
    d: 'M4.66636 5.65525L8.0023 2.32073L11.3336 5.65074',
  },
}

export const ExpandCollapseAnimatedIcon = ({ expanded, ...props }: ExpandCollapseAnimatedIconProps) => {
  return (
    <IconBase name="expand-collapse-animated" viewBox="0 0 16 16" {...props}>
      <motion.path
        variants={topVariants}
        animate={expanded ? 'expanded' : 'collapsed'}
        fill="none"
        strokeWidth="1.44"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <motion.path
        variants={bottomVariants}
        animate={expanded ? 'expanded' : 'collapsed'}
        fill="none"
        strokeWidth="1.44"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </IconBase>
  )
}
