import styled from '@emotion/styled'
import { useId } from '@react-aria/utils'
import React from 'react'
import { css } from 'theme-ui'
import { Flex } from '../../Flex'
import { Pressable } from '../../Pressable'
import { Text } from '../../Text'

export type FlyoutItemProps = React.ComponentPropsWithoutRef<typeof FlyoutItemBase> & {
  startIcon?: React.ReactElement | null
  endIcon?: React.ReactElement | null
  children?: React.ReactNode
  hint?: React.ReactNode
  active?: boolean
}

const ICON_PROPS = {
  'aria-hidden': 'true',
  focusable: 'false',
} as const

const FlyoutItemBase = styled(Pressable)<{ active?: boolean }>(({ theme, active }) =>
  css({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    color: 'text',
    minWidth: '200px',
    height: '32px',
    paddingX: 2,
    gap: 1,
    transition: theme.transition.default,
    bg: active ? 'background-light-alpha' : 'background',
    '&:hover': {
      bg: 'background-light-alpha',
    },
    whiteSpace: 'pre',
  }),
)

export const FlyoutItem = React.forwardRef<HTMLButtonElement, FlyoutItemProps>(
  ({ startIcon, endIcon, children, hint, ...props }, ref) => {
    const startIconNode = startIcon ? React.cloneElement(startIcon, ICON_PROPS) : null
    const endIconNode = endIcon ? React.cloneElement(endIcon, ICON_PROPS) : null

    const labelId = useId()

    return (
      <FlyoutItemBase role="menuitem" aria-labelledby={labelId} {...props} ref={ref}>
        {startIconNode}
        {children && (
          <Text sx={{ flex: 1, textAlign: 'start' }} id={labelId}>
            {children}
          </Text>
        )}
        {(endIconNode || hint) && (
          <Flex gap={1} align="center" sx={{ color: 'text-light' }}>
            {hint && <Text>{hint}</Text>}
            {endIconNode}
          </Flex>
        )}
      </FlyoutItemBase>
    )
  },
)

FlyoutItem.displayName = 'FlyoutItem'
