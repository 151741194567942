import styled from '@emotion/styled'
import { useCheckboxGroupItem } from '@react-aria/checkbox'
import { useFocusRing } from '@react-aria/focus'
import { useObjectRef } from '@react-aria/utils'
import { VisuallyHidden } from '@react-aria/visually-hidden'
import type { AriaCheckboxGroupItemProps } from '@react-types/checkbox'
import React from 'react'
import { css, ThemeUIStyleObject } from 'theme-ui'
import { Flex } from '../Flex'
import { CheckboxContext } from './context'

export type CheckboxButtonProps = AriaCheckboxGroupItemProps & {
  disabled?: boolean
  className?: string
  sx?: ThemeUIStyleObject
}

export const CheckboxButton = React.forwardRef<HTMLInputElement, CheckboxButtonProps>(
  ({ disabled = false, className, ...rest }, ref) => {
    const state = React.useContext(CheckboxContext)
    if (state == null) {
      throw new Error('Must be descendant of the CheckboxGroup')
    }

    const objRef = useObjectRef(ref)
    const { inputProps } = useCheckboxGroupItem(rest, state, objRef)
    const { isFocusVisible, focusProps } = useFocusRing()

    const { value, children } = rest
    const isDisabled = state.isDisabled || disabled
    const isSelected = state.isSelected(value)

    return (
      <Flex as="label" align="center" sx={{ my: 1 }} className={className}>
        <VisuallyHidden>
          <input {...inputProps} {...focusProps} disabled={isDisabled} ref={objRef} />
        </VisuallyHidden>
        <IconWrapper focusVisible={isFocusVisible} disabled={isDisabled} data-testid="CheckboxButton-icon">
          <svg
            style={{ display: 'block' }}
            width="1.25em"
            height="1.25em"
            viewBox="0 0 20 20"
            fill="currentcolor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M3.33325 2.49999C3.11224 2.49999 2.90028 2.58779 2.744 2.74407C2.58772 2.90035 2.49992 3.11231 2.49992 3.33333V16.6667C2.49992 16.8877 2.58772 17.0996 2.744 17.2559C2.90028 17.4122 3.11224 17.5 3.33325 17.5H16.6666C16.8876 17.5 17.0996 17.4122 17.2558 17.2559C17.4121 17.0996 17.4999 16.8877 17.4999 16.6667V3.33333C17.4999 3.11231 17.4121 2.90035 17.2558 2.74407C17.0996 2.58779 16.8876 2.49999 16.6666 2.49999H3.33325ZM1.56548 1.56556C2.03433 1.09672 2.67021 0.833328 3.33325 0.833328H16.6666C17.3296 0.833328 17.9655 1.09672 18.4344 1.56556C18.9032 2.0344 19.1666 2.67029 19.1666 3.33333V16.6667C19.1666 17.3297 18.9032 17.9656 18.4344 18.4344C17.9655 18.9033 17.3296 19.1667 16.6666 19.1667H3.33325C2.67021 19.1667 2.03433 18.9033 1.56548 18.4344C1.09664 17.9656 0.833252 17.3297 0.833252 16.6667V3.33333C0.833252 2.67029 1.09664 2.0344 1.56548 1.56556Z"
              fill="#71757D"
            />
            {isSelected && (
              <path
                d="M16.6666 0.833336H3.33325C2.67021 0.833336 2.03433 1.09673 1.56548 1.56557C1.09664 2.03441 0.833252 2.67029 0.833252 3.33334V16.6667C0.833252 17.3297 1.09664 17.9656 1.56548 18.4344C2.03433 18.9033 2.67021 19.1667 3.33325 19.1667H16.6666C17.3296 19.1667 17.9655 18.9033 18.4344 18.4344C18.9032 17.9656 19.1666 17.3297 19.1666 16.6667V3.33334C19.1666 2.67029 18.9032 2.03441 18.4344 1.56557C17.9655 1.09673 17.3296 0.833336 16.6666 0.833336ZM15.5891 6.4225L8.08908 13.9225C7.93281 14.0787 7.72089 14.1665 7.49992 14.1665C7.27895 14.1665 7.06702 14.0787 6.91075 13.9225L4.41075 11.4225C4.25895 11.2653 4.17496 11.0548 4.17686 10.8363C4.17876 10.6178 4.2664 10.4088 4.4209 10.2543C4.57541 10.0998 4.78442 10.0122 5.00292 10.0103C5.22141 10.0084 5.43192 10.0924 5.58908 10.2442L7.49992 12.155L14.4108 5.24417C14.4876 5.16458 14.5796 5.10109 14.6812 5.05742C14.7829 5.01374 14.8923 4.99076 15.0029 4.98979C15.1136 4.98883 15.2233 5.00992 15.3257 5.05182C15.4281 5.09372 15.5212 5.1556 15.5994 5.23384C15.6777 5.31208 15.7395 5.40513 15.7814 5.50754C15.8233 5.60995 15.8444 5.71969 15.8435 5.83034C15.8425 5.94099 15.8195 6.05034 15.7758 6.15201C15.7322 6.25368 15.6687 6.34563 15.5891 6.4225Z"
                fill="#4543B9"
              />
            )}
          </svg>
        </IconWrapper>
        {children}
      </Flex>
    )
  },
)

CheckboxButton.displayName = 'CheckboxButton'

const IconWrapper = styled.div<{ focusVisible: boolean; disabled: boolean }>(
  css({
    borderRadius: '50%',
    verticalAlign: 'text-bottom',
    marginRight: 1,
    marginY: 0.25,
    borderColor: 'text-light',
    padding: '1px',
  }),
  ({ disabled }) =>
    css({
      color: disabled ? 'text-light' : 'accent-background',
      cursor: disabled ? 'not-allowed' : 'pointer',
    }),
  ({ focusVisible }) =>
    focusVisible &&
    css({
      borderRadius: 'default',
      outline: '2px solid',
      outlineColor: 'accent-background',
    }),
)
