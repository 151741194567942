import { RowMenuIcon } from '@mm/company-ui-icons'
import React, { useEffect, useState } from 'react'
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd'
import { Button } from '../Button'
import { FloatingTooltip, Popover } from '../FloatingUI'
import { PopupWrapper } from '../Popup'
import { Text } from '../Text'

export type DragHandleCellProps = {
  menu?: React.ReactNode
  draggable?: boolean
  isDragging?: boolean
  highlight?: boolean
  onMenuVisibilityChange?: (menuVisible: boolean) => void
  dragHandleProps?: DraggableProvidedDragHandleProps

  className?: string // to be able to style it with `styled`
}

export const DragHandle = ({
  menu,
  draggable,
  isDragging,
  highlight,
  onMenuVisibilityChange,
  dragHandleProps,
  className,
}: DragHandleCellProps) => {
  const hasMenu = !!React.Children.count(menu)
  const [tooltipVisible, setTooltipVisible] = useState(false)
  const [menuVisible, setMenuVisible] = useState(false)

  useEffect(() => {
    if (isDragging) {
      setTooltipVisible(false)
      setMenuVisible(false)
    }
  }, [isDragging])

  if (!hasMenu && !draggable) {
    return null
  }

  let renderHandle = (
    getProps: (props?: React.ComponentPropsWithRef<'button'>) => React.ComponentPropsWithRef<'button'>,
  ) => (
    <Button
      {...getProps(dragHandleProps)}
      style={{
        // override react-beautiful-dnd "grab" cursor
        cursor: !isDragging && hasMenu ? 'pointer' : undefined,
      }}
      size="small"
      variant="muted"
      startIcon={draggable ? <RowMenuIcon display="block" /> : undefined}
      sx={{
        color: isDragging || highlight ? 'text' : 'text-light',
        ':hover': {
          color: 'text',
        },
      }}
      textHidden
      showTooltip={false}
      className={className}
    >
      {[menu && 'Open menu', draggable && 'Drag to move'].filter(Boolean).join(' or ')}
    </Button>
  )

  if (draggable) {
    const innerRender = renderHandle
    // eslint-disable-next-line react/display-name -- this is not a component
    renderHandle = (getProps) => (
      <FloatingTooltip
        placement="bottom-start"
        tooltip={
          <PopupWrapper>
            <Text color="text-light" variant="small">
              <Text color="black" variant="small">
                Drag
              </Text>{' '}
              to move
              {hasMenu ? (
                <>
                  <br />
                  <Text color="black" variant="small">
                    Click
                  </Text>{' '}
                  for menu
                </>
              ) : null}
            </Text>
          </PopupWrapper>
        }
        open={tooltipVisible}
        onOpenChange={(open) => {
          if (!isDragging) {
            setTooltipVisible(open)
          }
        }}
      >
        {({ getReferenceProps }) => innerRender((props) => getProps(getReferenceProps(props)))}
      </FloatingTooltip>
    )
  }

  if (hasMenu) {
    const innerRender = renderHandle
    // eslint-disable-next-line react/display-name -- this is not a component
    renderHandle = (getProps) => (
      <Popover
        render={() => <PopupWrapper sx={{ px: 0 }}>{menu}</PopupWrapper>}
        placement="bottom-start"
        onOpenChange={(open) => {
          if (!isDragging) {
            setMenuVisible(open)
            onMenuVisibilityChange?.(open)
          }
        }}
        open={menuVisible}
      >
        {({ getReferenceProps }) => innerRender((props) => getProps(getReferenceProps(props)))}
      </Popover>
    )
  }

  return renderHandle((props) => props ?? {})
}
