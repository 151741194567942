import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useEffect, useMemo } from 'react'
import { Droppable, DroppableProvided } from 'react-beautiful-dnd'
import { TABLE_ROW_GROUP_DRAGGABLE_TYPE, TABLE_ROW_GROUPS_DROPPABLE_ID } from './constants'
import { RowGroups } from './RowGroups'
import { TableRoot } from './styles'
import { useTableContext } from './TableContext'
import { TableDragDropProvider, useTableDragDropContext } from './TableDragDropContext'
import { TableHead } from './TableHead'

export const TableContent = observer(function TableContent() {
  const {
    props: { useParentDragDropContext },
  } = useTableContext()

  return useParentDragDropContext ? (
    <BaseTableContent />
  ) : (
    <TableDragDropProvider>
      <BaseTableContent />
    </TableDragDropProvider>
  )
})

const BaseTableContent = observer(function BaseTableContent() {
  const {
    props: {
      onRowGroupDragEnd,
      onRowDragEnd,
      rowGroupStyles,
      tableStyles,
      extraHeader,
      noHeaders,
      useParentDragDropContext,
    },
  } = useTableContext()

  const { setOnRowGroupDragEnd, setOnRowDragEnd, draggingSomething } = useTableDragDropContext()

  useEffect(() => {
    if (!onRowDragEnd || !setOnRowDragEnd) return

    setOnRowDragEnd(() => onRowDragEnd)
  }, [onRowDragEnd, setOnRowDragEnd])

  useEffect(() => {
    if (!setOnRowGroupDragEnd || !onRowGroupDragEnd) return

    setOnRowGroupDragEnd(() => onRowGroupDragEnd)
  }, [onRowGroupDragEnd, setOnRowGroupDragEnd])

  const style = useMemo(() => {
    return {
      '& > *': {
        borderTop: '1px solid',
        borderTopColor: 'border',
        paddingTop: 1,
        marginTop: 1,
      },
      '& > *:first-of-type': {
        borderTop: 'none',
        paddingTop: 0,
        marginTop: 0,
      },
      ...rowGroupStyles,
    }
  }, [rowGroupStyles])

  const tableBody = useCallback(
    ({ innerRef, droppableProps, placeholder }: DroppableProvided) => (
      <div {...droppableProps} ref={innerRef} sx={style}>
        <RowGroups />
        <div
          sx={{
            ...rowGroupStyles,
            backgroundColor: 'transparent',
            border: 'none',
            padding: 0,
            margin: 0,
            '> div': {
              background: 'transparent',
              border: 'none',
            },
          }}
        >
          {placeholder}
        </div>
      </div>
    ),
    [style, rowGroupStyles],
  )

  const tableBodyWithoutDroppable = useMemo(
    () => (
      <div sx={style}>
        <RowGroups />
      </div>
    ),
    [style],
  )

  return (
    <TableRoot
      noHeaders={noHeaders}
      tableStyles={tableStyles}
      role="table"
      className={classNames({
        dragging: draggingSomething,
      })}
    >
      <TableHead />
      {extraHeader}
      {useParentDragDropContext ? (
        tableBodyWithoutDroppable
      ) : (
        <Droppable droppableId={TABLE_ROW_GROUPS_DROPPABLE_ID} type={TABLE_ROW_GROUP_DRAGGABLE_TYPE}>
          {tableBody}
        </Droppable>
      )}
    </TableRoot>
  )
})
