import { ListKeyboardDelegate, useSelectableCollection } from '@react-aria/selection'
import { chain } from '@react-aria/utils'
import { SingleSelectListProps } from '@react-stately/list'
import { AnimatePresence, motion } from 'framer-motion'
import React, { useEffect, useMemo } from 'react'
import { useSingleSelectListState } from 'react-stately'
import { UserSelectItem } from '../types'
import { UserSelectList } from './UserSelectList'
import { UserSelectSearchField } from './UserSelectSearchField'

export type UserSelectComboBoxProps = SingleSelectListProps<UserSelectItem> & {
  inputValue: string
  open: boolean
  onInputChange?: (value: string) => void
  onOpenChange?: (open: boolean) => void
  onCancel?: () => void
}

export function UserSelectComboBox({
  inputValue,
  open,
  onInputChange,
  onOpenChange,
  onCancel,
  items,
  ...rest
}: UserSelectComboBoxProps) {
  const inputRef = React.useRef<HTMLInputElement>(null)
  const listBoxRef = React.useRef<HTMLUListElement>(null)

  // Create a localized state of the list for use in both rendering and virtual selection.
  const state = useSingleSelectListState({
    ...rest,
    items,
  })

  const delegate = useMemo(
    () => new ListKeyboardDelegate(state.collection, state.disabledKeys, listBoxRef),
    [state.collection, state.disabledKeys, listBoxRef],
  )

  // Create a virtualized Collection of the items so that we can pass the handlers into the
  // search box to enable it to navigate the list.
  const { collectionProps } = useSelectableCollection({
    selectionManager: state.selectionManager,
    keyboardDelegate: delegate,
    disallowTypeAhead: true,
    disallowEmptySelection: true,
    shouldFocusWrap: true,
    ref: inputRef,
    isVirtualized: true,
  })

  const onKeyDown = (e: KeyboardEvent) => {
    switch (e.key) {
      case 'Enter':
        e.preventDefault()

        rest.onSelectionChange?.(state.selectionManager.focusedKey)
        break
      case 'Escape':
        e.preventDefault()

        onCancel?.()
        break
    }
  }

  // Refocus first element in the list when the list changes if there is a search term
  useEffect(() => {
    const focusedKey = state.selectionManager.focusedKey
    const firstKey = state.collection.getFirstKey()

    if ((!state.collection.getItem(focusedKey) || focusedKey !== firstKey) && inputValue !== '') {
      if (firstKey) {
        state.selectionManager.setFocusedKey(firstKey)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items])

  return (
    <>
      <UserSelectSearchField
        aria-label="Search"
        value={inputValue}
        onChange={onInputChange}
        onKeyDown={chain(collectionProps.onKeyDown, onKeyDown)}
        ref={inputRef}
        open={open}
        onOpenToggle={() => {
          onOpenChange?.(!open)
        }}
        showOpenButton={inputValue.length === 0}
      />

      <AnimatePresence initial={false}>
        {open && (
          <motion.div
            initial={{ height: 0 }}
            animate={{ height: 'auto' }}
            exit={{ height: 0 }}
            sx={{ overflow: 'hidden' }}
            transition={{ type: 'tween', duration: 0.15 }}
          >
            <UserSelectList
              aria-label="Options"
              shouldUseVirtualFocus
              shouldSelectOnPressUp
              listBoxRef={listBoxRef}
              state={state}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </>
  )
}
