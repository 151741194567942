import React, { Key, useCallback, useMemo, useState } from 'react'
import { useFilter } from 'react-aria'
import { Item } from 'react-stately'
import { useFeatureFlags } from '../../../helpers'
import { formatUserDisplayName } from '../../../helpers/formatUserDisplayName'
import { Avatar } from '../../Avatar'
import { UserSelectItem, UserSelectUser } from '../types'
import { UserSelectActiveUsers } from './UserSelectActiveUsers'
import { UserSelectComboBox } from './UserSelectComboBox'

export type ChangeValue = {
  operation: 'add' | 'remove'
  value: string
}

export type UserSelectMenuProps = {
  value: string | Array<string>
  options: Array<UserSelectUser>
  canRemoveSelected?: boolean
  onChange?: (change: ChangeValue) => void
  onCancel?: () => void
  onClickSelected?: (id: string, event: React.MouseEvent) => void
}

export function UserSelectMenu({
  value,
  options,
  canRemoveSelected,
  onChange,
  onCancel,
  onClickSelected,
}: UserSelectMenuProps) {
  const { forceUserSelectMenuOpenOnRender } = useFeatureFlags()
  const [searchString, setSearchString] = React.useState('')
  const { contains } = useFilter({ sensitivity: 'base' })

  const selected = useMemo(() => (Array.isArray(value) ? value : [value]), [value])
  const [open, setOpen] = useState(forceUserSelectMenuOpenOnRender ? true : selected.length === 0)
  const handleInputChange = useCallback((value: string) => {
    setSearchString(value)
    if (value.length > 0) {
      setOpen(true)
    }
  }, [])

  const filteredItems = useMemo(
    () =>
      options
        .filter((option) => contains(formatUserDisplayName(option.node), searchString))
        .filter((option) => !selected.includes(option.node.id))
        .map((option) => ({
          id: option.node.id,
          name: formatUserDisplayName(option.node),
          detail: option.detail,
          avatar: <Avatar name={formatUserDisplayName(option.node)} />,
        })),
    [contains, options, selected, searchString],
  )

  const selectedItems = useMemo(
    () =>
      options
        .filter((option) => selected.includes(option.node.id))
        .map((option) => ({
          id: option.node.id,
          name: formatUserDisplayName(option.node),
          detail: option.detail,
          avatar: <Avatar name={formatUserDisplayName(option.node)} />,
        })),
    [options, selected],
  )

  const handleAdd = (value: Key) => {
    if (value && typeof value === 'string') {
      // Clear search string when a user is added
      if (searchString) {
        setSearchString('')
      }

      onChange?.({ operation: 'add', value })
    }
  }

  const handleRemove = (value: string) => {
    if (canRemoveSelected) {
      onChange?.({ operation: 'remove', value })
    }
  }

  return (
    <>
      <UserSelectComboBox
        onCancel={onCancel}
        items={filteredItems}
        inputValue={searchString}
        onInputChange={handleInputChange}
        onSelectionChange={handleAdd}
        open={open}
        onOpenChange={setOpen}
      >
        {(item: UserSelectItem) => (
          <Item textValue={item.name}>
            {item.avatar}
            {item.name}
          </Item>
        )}
      </UserSelectComboBox>

      <UserSelectActiveUsers
        selectedItems={selectedItems}
        canRemoveSelected={canRemoveSelected}
        onRemoveSelected={handleRemove}
        onClickSelected={onClickSelected}
      />
    </>
  )
}
