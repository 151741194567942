import React from 'react'
import { TableProps } from './types'

export type TableContextValue = {
  props: TableProps<any>
}

const ctx = React.createContext<TableContextValue | null>(null)

export const { Provider: TableContextProvider } = ctx

export const useTableContext = () => {
  const value = React.useContext(ctx)
  if (value == null) {
    throw new Error('must be used by descendants of TableContextProvider')
  }
  return value
}
