import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { LoadingIcon } from '@mm/company-ui-icons'
import isHotkey from 'is-hotkey'
import React, { Suspense, useEffect, useRef, useState } from 'react'
import { css } from 'theme-ui'
import { Portal, useFeatureFlags, variant } from '../../helpers'
import { useOutsideClick } from '../../hooks'
import { ZIndexLayer } from '../../ZIndexLayer'
import { ErrorBoundary } from '../ErrorBoundary'
import { Flex } from '../Flex'
import { getFloatingUiRoot } from '../FloatingUI'
import { InternalErrorBanner } from '../InternalErrorBanner'

const fadeIn = keyframes({
  from: {
    opacity: 0,
  },
  to: {
    opacity: 1,
  },
})

const fadeOut = keyframes({
  from: {
    opacity: 1,
  },
  to: {
    opacity: 0,
  },
})

const slideIn = keyframes({
  from: {
    transform: 'translateX(20px)',
    opacity: 0,
  },
  to: {
    transform: 'translateX(0)',
    opacity: 1,
  },
})

const slideOut = keyframes({
  from: {
    transform: 'translateX(0)',
    opacity: 1,
  },
  to: {
    transform: 'translateX(20px)',
    opacity: 0,
  },
})

const animationDuration = '200ms'
const animationTimingFunction = 'ease'

type DrawerVariant = 'rounded' | 'flat' | 'fullscreen'

export const DrawerBase = styled.div<{
  variant: DrawerVariant
  nonBlockingDrawer?: boolean
  splitPanelMeetingPage?: boolean
}>(
  ({ nonBlockingDrawer, splitPanelMeetingPage }) =>
    css({
      animationDuration,
      animationTimingFunction,

      position: 'fixed',
      top: 0,
      right: 0,
      bottom: 0,
      width: nonBlockingDrawer ? ['calc(100% - 50px)', '50%'] : ['calc(100% - 50px)', '70%'],
      minWidth: nonBlockingDrawer ? ['auto', '600px'] : ['auto', '860px'],
      maxWidth: nonBlockingDrawer ? '50vw' : '1000px',
      zIndex: ZIndexLayer.LAYER_1_DRAWER,

      ...(nonBlockingDrawer
        ? {
            borderLeft: '1px solid',
            borderLeftColor: 'border',
            boxShadow: splitPanelMeetingPage ? 'none' : 'default',
          }
        : {}),
    }),

  variant({
    prop: 'variant',
    variants: {
      rounded: {
        background: 'white',
        boxShadow: 'default',
        borderRadius: 'default',
        margin: 2,
      },
      flat: {
        background: 'white',
      },
      fullscreen: {
        width: '100% !important',
        maxWidth: '100% !important',
      },
    },
  }),
)

type BackdropProps = {
  backgroundColor?: string
  zIndex: number
}

export const Backdrop = styled.div<BackdropProps>(({ backgroundColor, zIndex }) =>
  css({
    animationDuration,
    animationTimingFunction,

    position: 'fixed',
    inset: '0',
    backgroundColor,
    zIndex,
  }),
)

Backdrop.defaultProps = {
  backgroundColor: 'background-strong-alpha',
}

export type DrawerProps = React.ComponentPropsWithRef<'div'> & {
  open?: boolean
  onHide?: () => void
  variant?: DrawerVariant
}

export function Drawer({
  open,
  onHide,
  variant = 'rounded',
  children,
  ...props
}: DrawerProps): React.ReactElement | null {
  const [displayed, setDisplayed] = useState(open)
  const { nonBlockingDrawer, splitPanelMeetingPage } = useFeatureFlags()
  const drawerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (open) {
      setDisplayed(true)
    }
  }, [open])

  useEffect(() => {
    if (!open || !onHide) return

    const handleKey = (event: KeyboardEvent) => {
      if (isHotkey('Escape', event)) {
        const activeElement = document.activeElement as HTMLElement | null

        if (
          activeElement?.tagName === 'TEXTAREA' ||
          activeElement?.tagName === 'INPUT' ||
          activeElement?.isContentEditable
        ) {
          activeElement.blur()
        } else {
          onHide()
        }
      }
    }
    document.addEventListener('keydown', handleKey, false)
    return () => {
      document.removeEventListener('keydown', handleKey, false)
    }
  }, [open, onHide])

  useOutsideClick(
    drawerRef,
    (event) => {
      if (!nonBlockingDrawer || variant === 'fullscreen') {
        return
      }

      // chect all parents of event.target to see if they are within a persist drawer element
      let target = event.target as HTMLElement | null
      while (target != null) {
        const shouldPersist = target.getAttribute('data-persist-drawer')
        switch (shouldPersist) {
          case 'true':
            // Keep Open
            return
          case 'false':
            onHide?.()
            return
        }

        // Skip closing if click is inside loom record SDK.
        // Cant put as parent to ignore since they create 3 elements with this id...
        if (target.id === 'loom-sdk-record-overlay-shadow-root-id') {
          return
        }

        target = target.parentElement
      }

      onHide?.()
    }, // Escape hatch to make sure we don't close the form when clicking on the user picker
    [getFloatingUiRoot()],
  )

  if (displayed) {
    return (
      <Portal>
        {!nonBlockingDrawer && (
          <Backdrop
            sx={{
              animationName: `${open ? fadeIn : fadeOut}`,
            }}
            onMouseDown={onHide}
            zIndex={ZIndexLayer.LAYER_1_DRAWER}
          />
        )}
        <DrawerBase
          ref={drawerRef}
          {...props}
          sx={{
            animationName: `${open ? slideIn : slideOut}`,
          }}
          onAnimationEnd={() => {
            if (!open) {
              setDisplayed(false)
            }
          }}
          role="dialog"
          variant={variant}
          nonBlockingDrawer={nonBlockingDrawer}
          splitPanelMeetingPage={splitPanelMeetingPage}
        >
          <ErrorBoundary fallback={<InternalErrorBanner />}>
            <Suspense
              fallback={
                <Flex align="center" justify="center" sx={{ height: '100%' }}>
                  <LoadingIcon width={20} height={20} color="text-medium" />
                </Flex>
              }
            >
              {children}
            </Suspense>
          </ErrorBoundary>
        </DrawerBase>
      </Portal>
    )
  } else {
    return null
  }
}
