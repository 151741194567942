import { Placement } from '@floating-ui/react'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useDistinct } from '../../../hooks'
import { Popover } from '../../FloatingUI'
import { PopupWrapper } from '../../Popup'
import { UserSelectUser } from '../types'
import { ChangeValue, UserSelectMenu } from './UserSelectMenu'
import { UserSelectTarget, UserSelectTargetProps } from './UserSelectTarget'
import { normalizeSelectValue } from './utils'

export type UserSelectOperation = {
  operation: 'add' | 'remove'
  value: string
}

type FixedTargetProps = 'value' | 'multiple' | 'withName'

type RenderTargetProps = { value: UserSelectUser[] } & Pick<UserSelectTargetProps, FixedTargetProps>

type DOMSelectProps = {
  name?: string
  'aria-label'?: string
  'aria-labelledby'?: string
}

export type UserSelectProps = DOMSelectProps & {
  placement?: Placement
  multiple?: boolean
  maxLength?: number
  options: UserSelectUser[]
  value?: string[] | string | null
  initialValue?: string[] | string | null
  withName?: boolean
  placeHolderAvatarSize?: number
  zIndexOverride?: number
  targetProps?: UserSelectTargetProps
  onChange?: (value: string[], operation: UserSelectOperation) => void
  onAdd?: (value: string) => void
  onRemove?: (value: string) => void
  onClickSelected?: (value: string, event: React.MouseEvent) => void
  children?: (props: RenderTargetProps) => React.ReactNode
  decorateMenu?: (menu: React.ReactNode) => React.ReactNode
}

export function UserSelect({
  placement = 'bottom-start',
  value,
  initialValue,
  options,
  multiple,
  placeHolderAvatarSize,
  withName,
  zIndexOverride,
  targetProps,
  onChange,
  onAdd,
  onRemove,
  onClickSelected,
  children,
  decorateMenu = (menu) => menu,
  ...domProps
}: UserSelectProps) {
  const [internalValue, setInternalValue] = React.useState<string[]>(
    normalizeSelectValue(value) ?? normalizeSelectValue(initialValue) ?? [],
  )

  const distinctControlledValue = useDistinct(normalizeSelectValue(value))

  useEffect(() => {
    if (distinctControlledValue !== undefined) {
      setInternalValue(distinctControlledValue)
    }
  }, [distinctControlledValue])

  const userValue = useMemo(
    () => options.filter((option) => internalValue.includes(option.node.id)),
    [internalValue, options],
  )

  const handleChange = useCallback(
    (change: ChangeValue) => {
      switch (change.operation) {
        case 'add': {
          const newValue = multiple ? [...internalValue, change.value] : [change.value]
          setInternalValue(newValue)
          onAdd?.(change.value)
          onChange?.(newValue, change)
          break
        }
        case 'remove': {
          const newValue = internalValue.filter((id) => id !== change.value)
          setInternalValue(newValue)
          onRemove?.(change.value)
          onChange?.(newValue, change)
          break
        }
      }
    },
    [internalValue, onChange, onAdd, onRemove, multiple],
  )

  return (
    <Popover
      // Have to pass an override to make sure it can be on top of certain elements (modals).
      // Ideally all modals should be in the same portal rool as popovers and this wouldn't be necessary.
      zIndex={zIndexOverride}
      placement={placement}
      render={({ hide }) => (
        <PopupWrapper
          sx={{
            padding: 0,
            width: '380px',
            borderRadius: 'medium', // TODO T-2709
            overflow: 'hidden',
          }}
        >
          {decorateMenu(
            <UserSelectMenu
              value={internalValue}
              options={options}
              onChange={(change: ChangeValue) => {
                handleChange(change)

                if (!multiple && change.operation === 'add') {
                  hide()
                }
              }}
              onCancel={hide}
              canRemoveSelected={multiple}
              onClickSelected={(id, event) => {
                hide()
                onClickSelected?.(id, event)
              }}
            />,
          )}
        </PopupWrapper>
      )}
    >
      {({ getReferenceProps }) => {
        const effectiveTargetProps = {
          ...getReferenceProps(),
          ...domProps,
          ...targetProps,
          value: userValue,
          placeHolderAvatarSize,
          multiple,
          withName: !multiple ? withName : false,
          role: 'combobox',
        }
        return children ? children(effectiveTargetProps) : <UserSelectTarget {...effectiveTargetProps} />
      }}
    </Popover>
  )
}
