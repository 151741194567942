import React from 'react'
import { IconBase, OptionalIconBaseProps } from '../components/IconBase'

export const SearchIcon = (props: OptionalIconBaseProps) => {
  return (
    <IconBase name="search" viewBox="0 0 20 20" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.33317 8.16669C2.33317 4.94503 4.94484 2.33335 8.1665 2.33335C11.3882 2.33335 13.9998 4.94503 13.9998 8.16669C13.9998 9.73831 13.3783 11.1648 12.3677 12.2137C12.3391 12.2356 12.3117 12.2596 12.2856 12.2858C12.2595 12.3119 12.2354 12.3393 12.2135 12.3678C11.1646 13.3785 9.73813 14 8.1665 14C4.94484 14 2.33317 11.3883 2.33317 8.16669ZM12.8479 14.0266C11.5648 15.053 9.93735 15.6667 8.1665 15.6667C4.02437 15.6667 0.666504 12.3088 0.666504 8.16669C0.666504 4.02455 4.02437 0.666687 8.1665 0.666687C12.3086 0.666687 15.6665 4.02455 15.6665 8.16669C15.6665 9.93753 15.0528 11.565 14.0264 12.8481L17.0891 15.9108C17.4145 16.2362 17.4145 16.7638 17.0891 17.0893C16.7637 17.4147 16.236 17.4147 15.9106 17.0893L12.8479 14.0266Z"
      />
    </IconBase>
  )
}
