import { useRadioGroup } from '@react-aria/radio'
import { useRadioGroupState } from '@react-stately/radio'
import type { AriaRadioGroupProps } from '@react-types/radio'
import type { ValueBase } from '@react-types/shared'
import React, { useMemo } from 'react'
import { ThemeUIStyleObject } from 'theme-ui'
import type { Merge } from 'type-fest'
import { RadioContext, RadioContextProps, RadioContextValue } from './context'

export type RadioGroupProps<T extends string = string> = Merge<AriaRadioGroupProps, ValueBase<T>> &
  RadioContextProps & {
    className?: string
    children?: React.ReactNode
    sx?: ThemeUIStyleObject
  }

export const RadioGroup = <T extends string>({ children, className, shape, ...props }: RadioGroupProps<T>) => {
  const state = useRadioGroupState(props as AriaRadioGroupProps)
  const { radioGroupProps, labelProps } = useRadioGroup(props as AriaRadioGroupProps, state)

  const contextValue = useMemo<RadioContextValue>(
    () => ({
      state,
      props: {
        shape,
      },
    }),
    [state, shape],
  )

  return (
    <div {...radioGroupProps} className={className}>
      {props.label && <span {...labelProps}>{props.label}</span>}
      <RadioContext.Provider value={contextValue}>{children}</RadioContext.Provider>
    </div>
  )
}
