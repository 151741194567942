import styled from '@emotion/styled'
import React from 'react'
import { css } from 'theme-ui'
import { AvatarBase, AvatarProps, Initials } from '../Avatar/Avatar'
import { Flex } from '../Flex'
import { FloatingTooltip } from '../FloatingUI'
import { PopupWrapper } from '../Popup'
import { Pressable } from '../Pressable'

export type FacepileProps = Omit<React.ComponentPropsWithRef<typeof Pressable>, 'children'> & {
  maxLength?: number
  size?: AvatarProps['size']
  children: React.ReactElement<AvatarProps> | Array<React.ReactElement<AvatarProps>>
}

export type FacepileBaseProps = {
  sizeVariant: FacepileProps['size']
}

const FacepileBase = styled(Pressable)<FacepileBaseProps>(() =>
  css({
    padding: 0.5,
    borderRadius: '20px',
    transition: '150ms background-color ease-in-out',

    '&:enabled': {
      '&:hover': {
        bg: 'background-light-alpha',
      },
      '&:active': {
        bg: 'background-medium-alpha',
      },
    },
  }),
)

export const Facepile = React.forwardRef<HTMLButtonElement, FacepileProps>(
  ({ maxLength = 5, size = 'medium', children, ...rest }, ref) => {
    const allNames: string[] = []
    const avatarList: Array<React.ReactElement> = []

    const childrenCount = React.Children.count(children)
    React.Children.forEach<FacepileProps['children']>(children, (child, i) => {
      if (React.isValidElement(child)) {
        if (avatarList.length + 1 < maxLength || childrenCount === maxLength) {
          avatarList.push(React.cloneElement(child, { size, key: i }))
        }
        allNames.push(child.props.name)
      } else {
        // eslint-disable-next-line no-console
        console.warn('This component only allows the Avatar component as its children')
      }
    })

    const overflowedNames = allNames.slice(avatarList.length)

    return (
      <FacepileBase ref={ref} sizeVariant={size} {...rest}>
        {/* The div element is used to override ARIA value attribute when the Facepile itself has role=combobox */}
        <Flex gap={0.5} aria-label={allNames.join(', ')}>
          {avatarList}
          {overflowedNames.length > 0 && (
            <FloatingTooltip
              openDelayMs={0}
              tooltip={<PopupWrapper sx={{ fontSize: 0, color: 'text' }}>{overflowedNames.join('\n')}</PopupWrapper>}
            >
              {({ getReferenceProps }) => (
                <AvatarBase
                  backgroundColor="background-light-alpha"
                  sx={{ color: 'text-light' }}
                  sizeVariant={size}
                  {...getReferenceProps()}
                >
                  <Initials>{`+${overflowedNames.length}`}</Initials>
                </AvatarBase>
              )}
            </FloatingTooltip>
          )}
        </Flex>
      </FacepileBase>
    )
  },
)

Facepile.displayName = 'Facepile'
