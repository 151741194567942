import styled from '@emotion/styled'
import { css } from 'theme-ui'

export const FlyoutDivider = styled.div(
  css({
    borderTop: '1px solid',
    borderColor: 'background-medium-alpha',
    marginTop: 1,
    marginBottom: 1,
  }),
)
