import { MeetingTeamsGroupsUsersIcon } from '@mm/company-ui-icons'
import { useId } from '@react-aria/utils'
import React from 'react'
import { Flex } from '../../Flex'
import { Text } from '../../Text'
import { UserSelectItem } from '../types'
import { UserSelectActiveOption } from './UserSelectActiveOption'

type UserSelectActiveUsersProps = {
  selectedItems: Array<UserSelectItem>
  canRemoveSelected?: boolean
  onRemoveSelected?: (id: string) => void
  onClickSelected?: (id: string, event: React.MouseEvent) => void
}

export function UserSelectActiveUsers({
  selectedItems,
  canRemoveSelected,
  onRemoveSelected,
  onClickSelected,
}: UserSelectActiveUsersProps) {
  const labelId = useId()

  if (selectedItems.length === 0) {
    return (
      <Flex row align="center" gap={1.5} sx={{ padding: 2 }}>
        <MeetingTeamsGroupsUsersIcon display="block" color="text-disabled" />
        <Text color="text-disabled">No one selected</Text>
      </Flex>
    )
  } else {
    return (
      <div sx={{ paddingY: 1 }}>
        <Text
          id={labelId}
          variant="small"
          uppercase
          color="text-light"
          sx={{
            display: 'block',
            paddingX: 2,
            paddingY: 1,
          }}
        >
          Selected
        </Text>
        <ul
          aria-labelledby={labelId}
          sx={{
            padding: 0,
            margin: 0,
            listStyle: 'none',
            maxHeight: 260,
            overflowY: 'auto',
          }}
        >
          {selectedItems.map((item) => (
            <UserSelectActiveOption
              {...item}
              key={item.id}
              showRemove={canRemoveSelected}
              onRemove={onRemoveSelected}
              onClick={onClickSelected}
            />
          ))}
        </ul>
      </div>
    )
  }
}
