import styled from '@emotion/styled'
import { CloseSmallIcon } from '@mm/company-ui-icons'
import React, { useCallback } from 'react'
import { css } from 'theme-ui'
import { Button } from '../../Button'
import { Flex } from '../../Flex'
import { Pressable } from '../../Pressable'
import { Text } from '../../Text'
import { UserSelectItem } from '../types'

export type UserSelectActiveOptionProps = UserSelectItem & {
  showRemove?: boolean
  onClick?: (id: string, event: React.MouseEvent) => void
  onRemove?: (id: string) => void
}

const MainButton = styled(Pressable)(
  css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexGrow: 1,
    gap: 1,
    borderRadius: 'medium',
    px: 1,
    py: 0.5,

    '&:hover': {
      backgroundColor: 'background-light-alpha',
    },
    '&:active': {
      backgroundColor: 'background-medium-alpha',
    },
  }),
)

const FlexListItem = Flex.withComponent('li')

export function UserSelectActiveOption({
  id,
  avatar,
  name,
  detail,
  showRemove,
  onClick,
  onRemove,
  ...rest
}: UserSelectActiveOptionProps) {
  const handleClick = useCallback<React.MouseEventHandler>(
    (event) => {
      onClick?.(id, event)
    },
    [id, onClick],
  )
  const handleRemove = useCallback<React.MouseEventHandler>(() => {
    onRemove?.(id)
  }, [id, onRemove])

  return (
    <FlexListItem row gap={0.5} sx={{ px: 0.5 }} {...rest}>
      <MainButton onClick={handleClick}>
        {avatar}
        <div sx={{ flex: 1 }}>{name}</div>
        {detail && (
          <Text variant="small" color="text-light">
            {detail}
          </Text>
        )}
      </MainButton>
      {showRemove && (
        <Button
          onClick={handleRemove}
          startIcon={<CloseSmallIcon />}
          variant="muted"
          size="small"
          textHidden
          sx={{ height: '32px', width: '32px' }} // TODO T-2703
        >
          {`Remove ${name}`}
        </Button>
      )}
    </FlexListItem>
  )
}
