import React from 'react'
import { IconBase, OptionalIconBaseProps } from '../components/IconBase'

export const CloseSmallIcon = (props: OptionalIconBaseProps) => (
  <IconBase name="close-small" viewBox="0 0 20 20" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.9226 7.2559C14.2481 6.93047 14.2481 6.40283 13.9226 6.07739C13.5972 5.75195 13.0696 5.75195 12.7441 6.07739L10 8.82147L7.25596 6.07739C6.93053 5.75195 6.40289 5.75195 6.07745 6.07739C5.75201 6.40283 5.75201 6.93047 6.07745 7.2559L8.82153 9.99998L6.07745 12.7441C5.75201 13.0695 5.75201 13.5971 6.07745 13.9226C6.40289 14.248 6.93053 14.248 7.25596 13.9226L10 11.1785L12.7441 13.9226C13.0696 14.248 13.5972 14.248 13.9226 13.9226C14.2481 13.5971 14.2481 13.0695 13.9226 12.7441L11.1786 9.99998L13.9226 7.2559Z"
    />
  </IconBase>
)
