import { Button, formatUserDisplayName, Modal, ModalSection, ZIndexLayer } from '@mm/company-ui'
import React, { useState } from 'react'
import { GetUserDocument, MeetingParticipantsAddDocument, MeetingTitleDocument } from '../../../gen/graphql/documents'
import { useMutation, useQuery } from '../../apollo'

export type InviteParticipantModalProps = {
  meetingId: string
  userId?: string
  isOpen: boolean
  onRequestClose?: () => void
}

export const InviteParticipantModal = ({ meetingId, userId, isOpen, onRequestClose }: InviteParticipantModalProps) => {
  const [addMeetingParticipant] = useMutation(MeetingParticipantsAddDocument)
  const [mutating, setMutating] = useState(false)

  const { data: meetingData } = useQuery(MeetingTitleDocument, {
    variables: {
      id: meetingId,
    },
    skip: !isOpen,
  })

  const { data: userData } = useQuery(GetUserDocument, {
    variables: {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      id: userId!,
    },
    skip: !isOpen || !userId,
  })

  const user = userData?.user

  if (!user) {
    return null
  }

  const firstName = formatUserDisplayName(user, 'FIRST_NAME_WITH_FALLBACK')

  const handleSubmit = async () => {
    setMutating(true)
    await addMeetingParticipant({
      variables: {
        meetingId,
        userId: user.id,
        role: 'PARTICIPANT',
      },
    })
    setMutating(false)
    onRequestClose?.()
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      title={`Invite ${formatUserDisplayName(user)} to ${meetingData?.meeting?.title ?? 'this meeting'}?`}
      overlayStyle={{
        // Make sure this is above any popovers (like QuickAddActions) that may trigger it
        zIndex: ZIndexLayer.LAYER_999_INFINITY,
      }}
    >
      <ModalSection>
        {firstName} is not a member of this meeting and does not have access to this topic. What would you like to do
        next?
      </ModalSection>
      <ModalSection justify="flex-end" gap={1.5}>
        <Button size="small" onClick={() => onRequestClose?.()}>
          Do nothing
        </Button>
        <Button size="small" variant="accent" onClick={handleSubmit} loading={mutating}>
          {`Invite ${firstName}`}
        </Button>
      </ModalSection>
    </Modal>
  )
}
