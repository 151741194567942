import { useAuth0 } from '@auth0/auth0-react'
import { toast } from '@mm/company-ui'
import { Auth0Callback, Auth0Error, WebAuth } from 'auth0-js'
import { Route } from 'nextjs-routes'
import { useCallback } from 'react'

let lazyStorage: WebAuth | null = null

const webAuth = () => {
  return (lazyStorage ??= new WebAuth({
    domain: process.env.NEXT_PUBLIC_AUTH0_ISSUER_DOMAIN,
    clientID: process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID,
    audience: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE,
    responseType: 'token id_token',
    redirectUri: typeof window === 'undefined' ? '' : `${window.location.origin}/auth0-redirect`,
  }))
}

export type SignInWithCredentialsOptions = {
  email: string
  password: string
  returnTo?: Route | string
  onError?: (error: { description: string }) => void
}

export const useSignInWithCredentials = () => {
  const { loginWithRedirect } = useAuth0()

  const signInAsync = useCallback(
    async ({ returnTo, email, password, onError }: SignInWithCredentialsOptions) => {
      const authUrl = new URL('/co/authenticate', 'https://' + process.env.NEXT_PUBLIC_AUTH0_ISSUER_DOMAIN)
      const result = await fetch(authUrl, {
        method: 'POST',
        credentials: 'include',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          client_id: process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID,
          username: email,
          password,
          realm: 'Username-Password-Authentication',
          credential_type: 'http://auth0.com/oauth/grant-type/password-realm',
        }),
      })

      if (!result.ok) {
        const errorObject = (await result.json()) as Auth0Error | undefined
        onError?.({
          description:
            errorObject?.errorDescription ||
            errorObject?.error_description ||
            errorObject?.description ||
            errorObject?.error ||
            'Unknown error',
        })
        return
      }

      const { login_ticket } = (await result.json()) as { login_ticket: string }

      await loginWithRedirect({
        login_ticket,
        appState: { returnTo },
      })
    },
    [loginWithRedirect],
  )

  return useCallback(
    ({ onError, ...options }: SignInWithCredentialsOptions) => {
      signInAsync({
        ...options,
        onError: (error) => {
          // eslint-disable-next-line no-console
          console.warn('Authentication failed', error)
          if (onError) {
            onError?.(error)
          } else {
            toast('Authentication failed', { type: 'error' })
          }
        },
      }).catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Authentication failed', error)
        toast('Authentication failed', { type: 'error' })
      })
    },
    [signInAsync],
  )
}

type LogoutOptions = {
  returnTo?: string
}

export const useLogout = () => {
  const { logout } = useAuth0()

  return useCallback(
    ({ returnTo }: LogoutOptions = {}) => {
      const returnToURL = new URL(window.location.origin)
      if (returnTo != null) {
        returnToURL.pathname = '/login'
        returnToURL.searchParams.set('returnTo', returnTo)
      }
      logout({
        returnTo: returnToURL.href,
      })
    },
    [logout],
  )
}

export type RecoverPasswordOptions = {
  email: string
  onDone: Auth0Callback<any>
}

export const recoverPassword = ({ email, onDone }: RecoverPasswordOptions) => {
  webAuth().changePassword(
    {
      connection: 'Username-Password-Authentication',
      email,
    },
    onDone,
  )
}
