import isHotkey from 'is-hotkey'
import React, { useEffect, useRef, useState } from 'react'
import { Input } from '../Input'
import { Text } from '../Text'

export type RowGroupTitleProps = {
  value: string
  placeholder?: string
  editable?: boolean
  focus?: boolean
  className?: string
  onChange?: (title: string) => void
}

export const RowGroupTitle = ({ className, value, placeholder, editable, focus, onChange }: RowGroupTitleProps) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [currentValue, setCurrentValue] = useState<string>()

  useEffect(() => {
    if (focus) {
      inputRef.current?.focus()
    }
  }, [focus])

  if (editable) {
    return (
      <Input
        ref={inputRef}
        variant="muted"
        bold
        value={currentValue ?? value}
        placeholder={placeholder}
        className={className}
        onBlur={() => {
          if (currentValue !== undefined) {
            onChange?.(currentValue)
            setCurrentValue(undefined)
          }
        }}
        onChange={(value) => {
          setCurrentValue(value)
        }}
        onKeyDown={(event) => {
          if (isHotkey(['esc', 'enter'], event)) {
            event.currentTarget.blur()
          }
        }}
        sx={{
          width: 'fit-content', // To avoid the input from taking the full width of the container
          marginLeft: -1, // Negative margin aligns the text of the input with the rest of the content on the page
        }}
      />
    )
  } else {
    return (
      <Text
        bold
        sx={{
          display: 'block',
          px: 1,
          paddingTop: 1,
          paddingBottom: 0,
          marginLeft: -1,
          lineHeight: 1.2,
          height: 4,
        }}
        className={className}
      >
        {value}
      </Text>
    )
  }
}
