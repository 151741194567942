import { observer } from 'mobx-react-lite'
import React from 'react'
import { HeadCell, HeadRow } from './styles'
import { useTableContext } from './TableContext'

export const TableHead = observer(function TableHead() {
  const { props } = useTableContext()
  if (props.noHeaders) {
    return null
  }
  const { columns, noBorders, rowStyles, stickyHeader } = props
  return (
    <HeadRow role="row" noBorders={noBorders} rowStyles={rowStyles} stickyHeader={stickyHeader}>
      {columns.map(({ header, width, disableResizing }, index) => {
        return (
          <HeadCell key={index} role="columnheader" baseWidth={width} dynamicWidth={!disableResizing}>
            {header}
          </HeadCell>
        )
      })}
    </HeadRow>
  )
})
