import styled from '@emotion/styled'
import { SingleUserIcon } from '@mm/company-ui-icons'
import _ from 'lodash'
import React from 'react'
import { css } from 'theme-ui'
import { formatUserDisplayName } from '../../../helpers/formatUserDisplayName'
import { Avatar, AvatarProps } from '../../Avatar'
import { Facepile } from '../../Facepile'
import { Pressable } from '../../Pressable'
import { Text } from '../../Text'
import { UserSelectUser } from '../types'
import { normalizeSelectValue } from './utils'

export type UserSelectTargetProps = Omit<React.ComponentPropsWithRef<'button'>, 'value'> & {
  value?: UserSelectUser | UserSelectUser[] | null
  multiple?: boolean
  placeHolderAvatarSize?: number
  withName?: boolean
  icon?: React.ReactElement
  size?: AvatarProps['size']
  hasHover?: boolean
}

const SingleUserButton = styled(Pressable)<{ hasHover: boolean }>(({ hasHover }) =>
  css({
    display: 'inline-flex',
    alignItems: 'center',
    padding: 0.5,
    gap: 1,
    borderRadius: '20px',
    transition: '150ms background-color ease-in-out',

    ...(hasHover
      ? {
          '&:enabled': {
            '&:hover': {
              bg: 'background-light-alpha',
            },
            '&:pressed': {
              bg: 'background-medium-alpha',
            },
          },
        }
      : {}),
  }),
)

export const UserSelectTarget = React.forwardRef<HTMLButtonElement, UserSelectTargetProps>(
  (
    {
      multiple,
      value,
      withName,
      icon,
      disabled,
      size = 'medium',
      placeHolderAvatarSize = 3,
      hasHover = true,
      ...restProps
    },
    ref,
  ) => {
    const normalizedValue = normalizeSelectValue(value) ?? []
    const [singleValue] = normalizedValue

    const facepileAvatars = normalizedValue.map((user) => {
      const name = formatUserDisplayName(user.node)
      return <Avatar name={name} key={name} />
    })

    if (multiple) {
      if (facepileAvatars.length < 3) {
        facepileAvatars.push(
          ..._.times(placeHolderAvatarSize - normalizedValue.length, (i) => (
            <Avatar
              key={`placeholder-user-${i}`}
              name="Add User"
              icon={<SingleUserIcon color="text-light" />}
              status="empty"
              backgroundColor="transparent"
              size={size}
            />
          )),
        )
      }
      return (
        <Facepile ref={ref} disabled={disabled} {...restProps}>
          {facepileAvatars}
        </Facepile>
      )
    } else {
      return (
        <SingleUserButton ref={ref} disabled={disabled} {...restProps} hasHover={hasHover} sx={{ pr: withName && 2 }}>
          {singleValue ? (
            <Avatar name={formatUserDisplayName(singleValue.node)} icon={icon} size={size} />
          ) : (
            <Avatar
              name={disabled ? 'Not selected' : 'Pick User'}
              icon={<SingleUserIcon color="text-light" />}
              backgroundColor="transparent"
              status="empty"
              size={size}
            />
          )}
          {withName && (
            <Text color="text" variant={size === 'small' ? 'small' : 'body'}>
              {singleValue ? formatUserDisplayName(singleValue.node) : 'Pick User'}
            </Text>
          )}
        </SingleUserButton>
      )
    }
  },
)

UserSelectTarget.displayName = 'UserSelectTarget'
