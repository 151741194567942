import {
  autoUpdate,
  flip,
  FloatingNode,
  FloatingOverlay,
  offset,
  shift,
  useDismiss,
  useFloating,
  useFloatingNodeId,
  useInteractions,
  useRole,
} from '@floating-ui/react'
import React from 'react'
import { ZIndexLayer } from '../../ZIndexLayer'
import { FloatingPortal } from './FloatingPortal'
import { floatingTreeNode } from './floatingTreeNode'
import { Wrapper } from './styles'
import { useFloatingTreeNode } from './useFloatingTreeNode'
import { useOpenState, UseOpenStateProps } from './useOpenState'

export type ContextMenuRenderProps = {
  hide: () => void
}

export type ContextMenuChildrenFnProps = {
  getReferenceProps: (
    userProps?: (React.HTMLProps<HTMLElement> & { ref?: React.Ref<HTMLElement> }) | undefined,
  ) => Record<string, unknown>
}

export type ContextMenuProps = UseOpenStateProps & {
  render: (props: ContextMenuRenderProps) => React.ReactNode
  children: (props: ContextMenuChildrenFnProps) => React.ReactNode

  ariaLabel?: string
}

// inspired by https://codesandbox.io/s/trusting-rui-2duieo?file=/src/ContextMenu.tsx
// see also:
// https://floating-ui.com/docs/react-dom-interactions#examples
// https://codesandbox.io/s/optimistic-jennings-jmpgfk?file=/src/NestedPopover.tsx:0-2375 -- nesting popovers
// https://codesandbox.io/s/winter-tree-wmmffl?file=/src/Tooltip.tsx -- simple tooltips
// https://codesandbox.io/s/admiring-lamport-5wt3yg?file=/src/DropdownMenu.tsx -- keyboard interactable menus
export const ContextMenu = floatingTreeNode<ContextMenuProps>(function ContextMenu({
  children,
  render,
  ariaLabel,
  ...openStateProps
}) {
  const [open, setOpen] = useOpenState(openStateProps)
  const nodeId = useFloatingNodeId()
  const { hide } = useFloatingTreeNode({
    onOpenChange: setOpen,
  })

  const {
    x,
    y,
    refs: { setReference, setFloating },
    strategy,
    context,
  } = useFloating({
    nodeId,
    open,
    onOpenChange: setOpen,
    middleware: [offset({ mainAxis: 0, crossAxis: -8 }), flip(), shift()],
    placement: 'right-start',
    whileElementsMounted: autoUpdate,
  })

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useRole(context, { role: 'menu' }),
    useDismiss(context),
  ])

  return (
    <FloatingNode id={nodeId}>
      {children({
        getReferenceProps: (props) =>
          getReferenceProps({
            ...props,
            onContextMenu: (e) => {
              e.preventDefault()
              setReference({
                getBoundingClientRect: () => ({
                  x: e.clientX,
                  y: e.clientY,
                  width: 0,
                  height: 0,
                  top: e.clientY,
                  right: e.clientX,
                  bottom: e.clientY,
                  left: e.clientX,
                }),
              })
              setOpen(true)
            },
          }),
      })}
      <FloatingPortal>
        {open && (
          <FloatingOverlay sx={{ zIndex: ZIndexLayer.LAYER_3_POPOVER }}>
            <Wrapper
              {...getFloatingProps({
                ref: setFloating,
                style: {
                  top: y ?? 0,
                  left: x ?? 0,
                },
                'aria-label': ariaLabel,
              })}
              sx={{
                position: strategy,
              }}
            >
              {render({
                hide,
              })}
            </Wrapper>
          </FloatingOverlay>
        )}
      </FloatingPortal>
    </FloatingNode>
  )
})
